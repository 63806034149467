import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ICdr } from '@app/services/callhistory/callhistory.domain';
import { ICallQueue } from '@app/services/web-apis/call-queues/api-call-queues.domain';
import { ApiUsersService, FeatureFlagsService } from '@app/services';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { ARGENTINA, BRAZIL, MEXICO } from '@app/Common';
import { environment } from '@env/environment';

import { ICallRules } from './details-to.domain';

@Component({
	selector: 'app-details-to',
	templateUrl: './details-to.component.html',
	styleUrls: ['./details-to.component.scss'],
})
export class DetailsToComponent {
	@Input() call: ICdr;
	@Input() isQueueInfoVisible: boolean;
	@Input() callQueues: ICallQueue[];

	public isCountryWithSimpleFormatting$: Observable<boolean> = this.apiTenantService.isAccountCountries$([
		...BRAZIL,
		...MEXICO,
		...ARGENTINA,
	]);

	shouldShowClickToCall$: Observable<boolean> = combineLatest([
		this.apiUsersService.user$.pipe(map(user => user.userId)),
		this.featureFlagsService.callHistoryClickToCall(),
		of(environment.is_admin_app),
		of(environment.is_pam_console),
	]).pipe(
		map(([currentUserId, featureEnabled, isAdminApp, isPamConsole]) => {
			const { to } = this.call;
			return !isAdminApp && !isPamConsole && to && featureEnabled && (!to.userId || currentUserId !== to.userId);
		}),
	);

	constructor(
		private apiUsersService: ApiUsersService,
		private featureFlagsService: FeatureFlagsService,
		private apiTenantService: ApiTenantService,
	) {}

	get callRules(): ICallRules {
		const { to, dnis = '' } = this.call;
		const shouldShowSpecialExtension: boolean = to.userBusinessClass && to.userBusinessClass !== 'US';
		const shouldShowNumOnly: boolean = (!to?.userDisplayName || dnis[0] === '*') && !this.call.to.callQueueId;
		const shouldShowExt: boolean =
			to?.number.length <= this.apiTenantService.accountMaxExtensionLength && !shouldShowNumOnly;
		const shouldShowWM: boolean = !!to?.menuDisplayName;
		const shouldShowRG: boolean = !!to?.ringGroupDisplayName;
		const shouldShowCallQueue: boolean = this.isQueueInfoVisible && !!to?.callQueueDisplayName;
		const shouldShowNum: boolean = !!dnis && !shouldShowExt;
		const shouldShowDirect: boolean = !shouldShowExt && !!to?.userDisplayName;

		return {
			top: {
				shouldShowDepartment: !!to?.departmentDisplayName,
				shouldShowRG: shouldShowRG && shouldShowWM, // only show on top if welcome menu is also included
				shouldShowSpecialExtension,
				shouldShowCallQueue,
			},
			bot: {
				shouldShow: shouldShowExt || shouldShowWM || shouldShowRG || shouldShowNum,
				shouldShowExt,
				shouldShowWM,
				shouldShowRG: shouldShowRG && !shouldShowWM,
				shouldShowNum,
				shouldShowNumOnly,
				shouldShowDirect,
			},
		};
	}

	get callQueue(): ICallQueue | undefined {
		const { callQueueId } = this.call.to;
		return this.callQueues.find(queue => {
			return String(queue.id) === String(callQueueId);
		});
	}

	get firstCallQueuePhoneNumber(): string | undefined {
		const {
			top: { shouldShowCallQueue },
			bot: { shouldShowExt },
		} = this.callRules;
		if (!shouldShowExt || !shouldShowCallQueue || !this.call.to.callQueueId) {
			return undefined;
		}
		return this.callQueue?.phone_numbers?.[0]?.phone_number;
	}

	get callQueueExtension(): string | undefined {
		const { shouldShowNum } = this.callRules.bot;
		if (!shouldShowNum || !this.call.to.callQueueId) {
			return undefined;
		}
		return this.callQueue?.extension ?? undefined;
	}
}
