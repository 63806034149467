import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { ApiService, FeatureFlagsService } from '@app/services';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import {
	IAccountPhone,
	IAccountPhoneCallerIdReplacementOptions,
} from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { formatAsE164 } from '@app/pages/phone-numbers/utils/formatters.utils';
import { IPhone } from '@app/Common';

@Injectable()
export class ApiPhoneNumbersService {
	private readonly _baseUrl: string = '/accounts/{accountId}/phonenumbers';
	private _usePhoneNumberService: boolean;

	constructor(private apiService: ApiService, private featureFlagsService: FeatureFlagsService) {
		this.featureFlagsService.usePhoneNumberService().subscribe(res => (this._usePhoneNumberService = res));
	}

	getAllPhoneNumbers(
		excludeSingleLineUsers: boolean = true,
		excludeVirtualFax: boolean = true,
		includePortingData: boolean = false,
	): Observable<IRegularApiResponse<IAccountPhone[]>> {
		const query = {
			bFilterUsersWithSingleLine: excludeSingleLineUsers,
			bFilterOutVirtualFax: excludeVirtualFax,
			bIncludePendingPortNumbers: includePortingData,
		};

		return this.apiService.get(this._baseUrl, query);
	}

	getPhoneNumbersByUserId({
		userId,
		filterUsersWithSingleLine = false,
		filterOutTollFreeNumbers = false,
		bFilterOutVirtualFax = true,
	}: {
		userId: string | number;
		filterUsersWithSingleLine?: boolean;
		filterOutTollFreeNumbers?: boolean;
		bFilterOutVirtualFax?: boolean;
	}): Observable<IRegularApiResponse<IAccountPhone[]>> {
		const url = `/accounts/{accountId}/phonenumbers?bFilterUsersWithSingleLine=${filterUsersWithSingleLine}&bFilterOutTollFreeNumbers=${filterOutTollFreeNumbers}&userId=${userId}&bFilterOutVirtualFax=${bFilterOutVirtualFax}`;

		return this.apiService.get(url);
	}

	getCallerIdUsers(lineNumber: string): Observable<any> {
		const url = `/accounts/{accountId}/callerId/${lineNumber}/users`;

		return this.apiService.get(url);
	}

	getPhoneNumberStats(): Observable<any> {
		return this.apiService.get(`${this._baseUrl}/stats`);
	}

	updatePhoneNumber(
		phoneData: IPhone,
		ignoreUS911: boolean = false,
	): Observable<any> {
		return this.apiService.patch(`${this._baseUrl}/${phoneData.number}?ignoreUS911=${ignoreUS911}`, phoneData);
	}

	updateForbiddenAsCallerId(phoneNumber: string, isActive: boolean): Observable<void> {
		return this.apiService
			.patch<IRegularApiResponse<string>>(`${this._baseUrl}/${phoneNumber}/forbidAsCallerId`, { isActive })
			.pipe(
				map(res => {
					if (res.hasError || res.data !== 'success') {
						throw new Error();
					}
				}),
			);
	}

	deletePhoneNumber = (phoneNumber: number | string): Observable<any> => {
		if (this._usePhoneNumberService) {
			return this.apiService.delete('/catalogs/{catalogId}/phone-numbers', {
				phoneNumber: formatAsE164(phoneNumber.toString()),
			});
		}

		return this.apiService.delete(`${this._baseUrl}/${phoneNumber}`);
	};

	getCallerIdReplacementOptions(lineNumber: string): Observable<IAccountPhoneCallerIdReplacementOptions> {
		const url = `/accounts/{accountId}/phonenumbers/${lineNumber}/callerIdReplacementOptions`;

		return this.apiService.get<IRegularApiResponse<IAccountPhoneCallerIdReplacementOptions>>(url).pipe(
			map(res => {
				if (res.hasError) {
					throw new Error();
				}
				return res.data;
			}),
		);
	}
}
