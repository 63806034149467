import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiDirectoriesService, FeatureFlagsService, SnackbarService } from '@app/services';
import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';

import { FileSizePipe } from '@app/pipes/file-size/file-size.pipe';
import { DataStorageUnit } from 'n2p-ui-library';

@Component({
	selector: 'n2p-file-uploader-popup',
	templateUrl: './file-uploader-popup.component.html',
	styleUrls: ['./file-uploader-popup.component.scss'],
})
export class FileUploaderPopupComponent implements OnInit {
	DATA_STORAGE_UNIT = DataStorageUnit.MEGABYTE;
	uploading: boolean = false;

	maxFileSize: number = 4e6;
	title: string;
	subtitle: string;
	fileType: 'image' | 'audio' = 'audio';
	uploadAction: string;
	userId: string;
	audioContent: string;
	error: string;

	file: File;
	// array of valid file extensions. Set in ngOnInit
	private validFileTypes: Array<string>;

	@ViewChild('bodySectionRef', { static: true }) bodySectionRef: ElementRef;

	constructor(
		@Inject(DIALOG_DATA) private dialogData: any,
		@Inject(DIALOG_REF) private dialogRef: any,
		// TODO need scalable options for all calls
		private apiDirectory: ApiDirectoriesService,
		private snackbarService: SnackbarService,
		private translateService: TranslateService,
		private featureFlagsService: FeatureFlagsService,
	) {
		if (this.dialogData) {
			const { title, subtitle, maxFileSize, fileType, uploadAction, userId } = this.dialogData;

			// required
			this.title = title;
			this.uploadAction = uploadAction;
			this.userId = userId;

			if (!this.title) {
				throw Error('title is required');
			}

			if (!this.uploadAction) {
				throw Error('uploadAction is required');
			}

			// optional
			if (subtitle) this.subtitle = subtitle;
			if (maxFileSize) this.maxFileSize = maxFileSize;
			if (fileType) this.fileType = fileType;
		}
	}

	ngOnInit(): void {
		// set valid file extensions
		this.validFileTypes = {
			audio: ['mp3', 'm4a', 'wav'],
			image: ['png', 'jpg', 'jpeg'],
		}[this.fileType];
	}

	/**
	 * Event handler for file added via drag and drop or file selections
	 * @param event
	 */
	handleFileChange(file: File | null): void {
		if (!file) {
			this.file = undefined;
			return;
		}

		this.file = file;

		// reset errors
		this.error = undefined;
	}

	handleFileError(error: Error): void {
		this.error = error.message;
		this.file = undefined;
	}

	handleUploadClick(): void {
		if (!this.file) return;

		this.uploading = true;

		this.uploadFile();
	}

	uploadFile(): void {
		const source$ = this.apiDirectory[this.uploadAction](this.file, this.userId);

		source$.subscribe(res => {
			if (res.hasError) {
				this.snackbarService.create({
					status: 'danger',
					text: this.translateService.instant('DIRECTORIES_PAGE.EDIT.GREETING_UPLOAD_FAIL'),
					connectTo: this.bodySectionRef,
				});
			} else {
				this.uploading = false;
				this.closeModal(true);
			}
		});
	}

	closeModal(value: boolean = false): void {
		if (!this.uploading) this.dialogRef.dismiss(value ? this.file : undefined);
	}
}
