import { Injectable } from '@angular/core';
import {
	IDropdownOption,
	ISelectedDropdownOption,
	IDropdownGroup,
} from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { Observable } from 'rxjs/Observable';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { DeviceServices, ApiPhoneNumbersService, SnackbarService, JwtService } from '@app/services';
import { PORTING, VALID_UNASSIGNED, getValidType } from '@app/Common/constants';
import {
	ICallerId,
	ICndInfo,
	IUserLineData,
} from '@app/services/web-apis/special-extensions/api-special-extensions.domain';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { ConstantsService } from '@app/services/constants/constants.service';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IPutCallerIdParams } from '@app/services/devices/devices.domain';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';

import { PhoneNumbersService } from '@app/pages/phone-numbers/phone-numbers-service/phone-numbers.service';
import { IAccountPhoneNumberV2 } from '@app/pages/phone-numbers/phone-numbers-service/phone-numbers.domain';

@Injectable()
export class TeamMemberService {
	phoneNumbers$: BehaviorSubject<IAccountPhone[]> = new BehaviorSubject([]);
	lineData$: BehaviorSubject<IUserLineData[]> = new BehaviorSubject([]);
	phoneOptions$: BehaviorSubject<IDropdownOption[]> = new BehaviorSubject([]);
	phoneGroups$: BehaviorSubject<IDropdownGroup[]> = new BehaviorSubject([]);
	assignedCallerId$: BehaviorSubject<string> = new BehaviorSubject('');
	isCallerIdRequired$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	isPhoneNumberRequired$: BehaviorSubject<boolean> = new BehaviorSubject(true);
	onPutCallerId$: Subject<IPutCallerIdParams> = new Subject();

	constructor(
		private deviceServices: DeviceServices,
		private phoneNumberFormatter: PhoneNumberFormatterPipe,
		public constantsService: ConstantsService,
		private snackbarService: SnackbarService,
		private apiPhoneNumbersService: ApiPhoneNumbersService,
		private phoneNumbersService: PhoneNumbersService,
		private translate: TranslateService,
		private jwtService: JwtService,
		private tenantService: ApiTenantService,
	) {}

	getInitData(
		userId: string | number,
	): Observable<
		[
			IRegularApiResponse<IAccountPhone[]>,
			IRegularApiResponse<ICndInfo>,
			IRegularApiResponse<ICallerId>,
			IRegularApiResponse<IAccountPhoneNumberV2[]>,
		]
	> {
		return combineLatest([
			this.getPhoneNumbers(userId),
			this.getRegisteredCallerId(userId),
			this.getCallerIds(userId),
			this.getAllPhoneNumbers(),
		]).pipe(
			tap(([phones, callerId, callerIds, allPhones]) => {
				if (phones && !phones.hasError) {
					this.phoneNumbers$.next(phones.data);
					this.isCallerIdRequired$.next(phones.data.every(p => !p.userInfo));
				} else {
					this.isCallerIdRequired$.next(true);
				}

				if (callerId && !callerId.hasError) {
					const { type, setting: id } = callerId.data;
					if (id && id !== '0') {
						this.assignedCallerId$.next(id);
						this.isPhoneNumberRequired$.next(false);
					} else {
						this.isPhoneNumberRequired$.next(true);
					}
				}

				if (callerIds && !callerIds.hasError) {
					this.setPhoneDropdownData(callerIds.data.lineData, allPhones?.data);
				}
			}),
		);
	}

	putCallerId(userId: string | number, option: ISelectedDropdownOption, previousCallerId: string): void {
		if (!option && this.isCallerIdRequired$.value) {
			this.snackbarService.createDanger(this.translate.instant('TEAMMEMBERS_PAGE.CALLER_ID_VALIDATION_ERROR'));
			return;
		}
		const putParams: IPutCallerIdParams = this.getPutCallerIdParams(userId, option);

		this.deviceServices.putCallerId(putParams).subscribe(res => {
			if (!res.hasError) {
				this.isPhoneNumberRequired$.next(!option);
				this.onPutCallerId$.next(putParams);
			} else {
				this.snackbarService.createDanger(this.translate.instant('TEAMMEMBERS_PAGE.CALLER_ID_ASSIGNING_ERROR'));
				this.assignedCallerId$.next(previousCallerId);
			}
		});
	}

	clearSubjects(): void {
		this.phoneNumbers$.next([]);
		this.lineData$.next([]);
		this.phoneOptions$.next([]);
		this.phoneGroups$.next([]);
		this.assignedCallerId$.next('');
		this.isCallerIdRequired$.next(true);
		this.isPhoneNumberRequired$.next(true);
	}

	getCallerIdPhoneOptions(
		userId: string | number,
	): Observable<[IRegularApiResponse<ICallerId>, IRegularApiResponse<IAccountPhoneNumberV2[]>]> {
		return combineLatest([this.getCallerIds(userId), this.getAllPhoneNumbers()]).pipe(
			tap(([callerIds, allPhones]) => {
				if (callerIds && !callerIds.hasError && allPhones) {
					this.setPhoneDropdownData(callerIds.data.lineData, allPhones.data);
				}
			}),
		);
	}

	getPhoneNumbers(
		userId: string | number,
		filterUsersWithSingleLine: boolean = true,
		filterOutTollFreeNumbers: boolean = false,
	): Observable<IRegularApiResponse<IAccountPhone[]>> {
		return this.apiPhoneNumbersService.getPhoneNumbersByUserId({ userId, filterUsersWithSingleLine, filterOutTollFreeNumbers });
	}

	private getAllPhoneNumbers(): Observable<IRegularApiResponse<IAccountPhoneNumberV2[]> | null> {
		if (this.tenantService.isUSAccount() && this.jwtService.hasPolicy('unite.user.callerid.port')) {
			return this.phoneNumbersService.getAllPhoneNumbersForCatalog();
		}
		return Observable.of(null);
	}

	private getRegisteredCallerId(userId: string | number): Observable<IRegularApiResponse<ICndInfo>> {
		return this.deviceServices.getRegisteredCallerId(userId);
	}

	private getCallerIds(userId: string | number): Observable<IRegularApiResponse<ICallerId>> {
		return this.deviceServices.getCallerId(userId);
	}

	private setPhoneDropdownData(lineData: IUserLineData[], phones: IAccountPhoneNumberV2[] | null): void {
		let allLineData = lineData;
		if (phones) {
			allLineData = allLineData.concat(this.getLineItemsForUnitePortingNumbers(phones));
		}
		let groups: IDropdownGroup[] = this.constantsService.standardPhoneGroups;
		const options: IDropdownOption[] = [];

		allLineData
			.sort((a, b) => (a.lineId > b.lineId ? 1 : -1))
			.forEach(ld => {
				const groupId = getValidType(ld.type);
				const option: IDropdownOption = {
					title: this.phoneNumberFormatter.transform({ number: ld.lineId }),
					subTitle: ld.displayName,
					value: ld.lineId,
					initials: this.constantsService.entityTypeInitialsMap[groupId],
				};

				groups = groups.map(g => {
					if (g.id !== groupId) return g;

					return {
						...g,
						options: g.options.concat(option),
					};
				});
			});

		const formattedAssignedNumber = this.getFormattedAssignedNumber(this.assignedCallerId$.value);
		const isNotLineData = allLineData.every(ld => this.formattedNumber(ld.lineId) !== formattedAssignedNumber);
		// CS tool can set a caller id that is not one of the account DIDs
		if (formattedAssignedNumber && isNotLineData) {
			options.push({
				title: this.phoneNumberFormatter.transform({ number: formattedAssignedNumber }),
				value: formattedAssignedNumber,
			});
		}

		this.lineData$.next(allLineData);
		this.phoneGroups$.next(groups);
		this.phoneOptions$.next(options);
	}

	private getFormattedAssignedNumber(number: string): string {
		return number ? this.formattedNumber(number.replace(/\D/g, '')) : '';
	}

	private formattedNumber(value: string): string {
		const phoneNumber = this.phoneNumberFormatter.transform({
			number: value,
			emptyOnInvalid: true,
			numberFormat: 'E.164',
		});

		// remove plus that can be added by phoneNumberFormatter.transform for not brazilian numbers
		return phoneNumber[0] === '+' ? phoneNumber.substring(1) : phoneNumber;
	}

	private getLineItemsForUnitePortingNumbers(phones: IAccountPhoneNumberV2[]): IUserLineData[] {
		let unitePendingNumbers = phones
			.filter(p => !!p.pendingNumber)
			.filter(p => p.applicationType === 'UNITE' || p.applicationType === 'UNASSIGNED');
		return unitePendingNumbers.map(phone => ({
			displayName: '',
			id: null,
			lineId: phone.pendingNumber.replace(/^\+/, ''),
			type: PORTING,
		}));
	}

	private getPutCallerIdParams(userId: string | number, option: IDropdownOption): IPutCallerIdParams {
		const lineItem = option && this.lineData$.value.find(ld => ld.lineId === option.value);

		// lineItem.id can be null for lineItem with PORTING type which we manually set
		const objectId = lineItem?.id ? lineItem.id.toString() : '';
		const type = lineItem ? (lineItem.type === PORTING ? VALID_UNASSIGNED : lineItem.type) : '';
		const setting = lineItem ? lineItem.lineId : '0';
		const isActive = !!option;

		return {
			userId,
			isActive,
			objectId,
			type,
			setting,
		};
	}
}
