import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { GlobalEvents, defaultOsDetectorUtil } from 'n2p-ui-library';
import { v4 as uuid } from 'uuid';
import { LoaderUtil } from 'n2p-ui-library/utils/loader/loader.util';

import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';
import consoleUserInfo from '@n2p/console-user-info/console-user-info';
import { WebAppManager } from '@app/web-app-manager';
import { getStorageOutsideOfAngular } from '@utils/helpers/impersonation';

document.documentElement.classList.add(defaultOsDetectorUtil.rootOSClassName);

const startApp = (): void => {
	const storage = getStorageOutsideOfAngular();

	if (environment.production) {
		enableProdMode();
	}

	GlobalEvents.subscribe();

	LoaderUtil.loadScript({
		src: 'https://cloudfront.loggly.com/js/loggly.tracker-latest.min.js',
		params: { async: true, id: 'logglyScript' },
	}).then(() => {});

	window._LTracker = [];
	window._LTracker.push({
		logglyKey: environment.logglyKey,
		sendConsoleErrors: environment.env === 'production' || environment.env === 'staging',
		tag: 'n2p-webapp_logs',
	});
	window.n2pLogger = {
		logJson: (data: any): void => {
			if (window._LTracker) {
				window._LTracker.push({
					env: environment.env,
					userId: storage.getItem('user_id'),
					accountId: storage.getItem('account_id'),
					tabId: sessionStorage.getItem('tab_id'),
					...data,
				});
			}
		},
	};

	if (!sessionStorage.getItem('tab_id')) {
		sessionStorage.setItem('tab_id', uuid());
	}

	if (!localStorage.getItem('browser_id')) {
		localStorage.setItem('browser_id', uuid());
	}

	consoleUserInfo();

	window.webAppManager = new WebAppManager();
	platformBrowserDynamic()
		.bootstrapModule(AppModule)
		.then(() => {});
};

if (environment.needToLoadEnv) {
	fetch('./env.json')
		.then(r => r.json())
		.then(data => {
			(window as any).FETCHED_ENV_DATA = data;
			// change public path for dynamic imports
			if (data.N2P_PUBLIC_PATH) {
				// @ts-ignore
				__webpack_public_path__ = data.N2P_PUBLIC_PATH;
			}

			startApp();
		})
		.catch(() => {
			(window as any).FETCHED_ENV_DATA = {};
			startApp();
		});
} else {
	startApp();
}
