import { CountryCode } from 'libphonenumber-js';
export enum AccountUsageType {
	DEMO = 'demo',
	TEST = 'test',
	REGULAR = 'regular',
}

export interface IAccountPolicy {
	intName: string;
	policyName: string;
	maxUsersDepartment: number;
	maxDepartments: number;
	maxRingsDepartment: number;
	maxRingsPhone: number;
	maxLengthOfCall: number;
	maxUsers: number;
	maxPhones: number;
	maxPhonesUser: number;
	maxRingsUser: number;
	maxPhonesDepartment: number;
	maxMenuDepth: number;
	maxMenuWidth: number;
	maxSpecialExtensions: number;
	maxExtensionLength: number;
}

export interface IAccount {
	accountId: number;
	firstName: string;
	lastName: string;
	emailAddress: string;
	phone: string;
	company: string;
	timeZone: string;
	financialStatus: string;
	ninjaId: string;
	ratecardInId: number;
	ratecardOutId: number;
	status: string;
	tomcatTicketId: string;
	agentCustomerId: number;
	salesManagerId: number;
	clientId: number;
	clientAccountId: string;
	engineerId: number;
	billingRepId: number;
	balance: number;
	creditLimit: number;
	maxUsers: number;
	maxPhones: number;
	maxSpecialExtensions: number;
	accountPolicies: IAccountPolicy[];
	applyTimezoneToAllUsers: boolean;
	country: CountryCode;
	canCreatePhysicalDevices: boolean;
	createdDateTime: string;
	canCreateDidlessUsers: boolean;
	currency: string;
	usageType: AccountUsageType;
}
