import { Component, Input } from '@angular/core';

import { getInitialsFromString } from 'utils/helpers/dropdown';

import { AvatarComponentType } from './avatar.domain';
import { avatarTypeMap } from './avatar.constants';

// this component only pertains to the icons shown in callhistory, there are a possible maximum of three stacked icons
// see designs for callhistory for reference

// TODO: refactor to simplify logic once call server changes are made and cdrs are accurately represented in cdr json

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss'],
})
export class AvatarComponent {
	public type: { primary: AvatarComponentType; secondary?: AvatarComponentType };
	@Input('type') set _type(type: AvatarComponentType) {
		this.type = avatarTypeMap[type || 'user'];
	}
	@Input() textPrimary?: string;
	@Input() textSecondary?: string;
	@Input() imgUrl: string;
	@Input() allowMultiple = true;
	@Input() showNew: boolean;

	public getInitialsFromString: Function = getInitialsFromString;

	constructor() {}

	get shouldShowImage(): boolean {
		return !!this.imgUrl;
	}

	get shouldShowIcon(): boolean {
		return !this.shouldShowImage && !this.textPrimary && !this.textSecondary;
	}

	get shouldShowInitialsPrimary(): boolean {
		return Boolean(!this.shouldShowImage && !this.shouldShowIcon && this.textPrimary);
	}

	get shouldShowInitialsSecondary(): boolean {
		return Boolean(this.allowMultiple && this.textSecondary);
	}
}
