import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { DataStorageUnit, InputStyleTypes } from 'n2p-ui-library/constants';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';

import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { AdminResetPasswordComponent } from '../admin-reset-password/admin-reset-password.component';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { DeleteTeamMemberComponent } from '../DeleteTeamMember/delete-team-member.component';
import {
	ApiUsersService,
	CommonService,
	DialogService,
	FeatureFlagsService,
	PromptService,
	SnackbarService,
	AuthStorageData,
	ApiAccountsService,
	JwtService,
} from '@app/services';
import { emailRegex } from '@app/Common';
import { Decision } from '@app/shared/classes/PromptRef';
import { SamlService } from '@app/services/web-apis/saml/saml.service';
import {
	SUPPORTED_LANGUAGES_FEATURE_FLAGS,
	SUPPORTED_LANGUAGE_VALUES_FOR_SELECTION,
	UI_LANGUAGE_STORAGE_KEY,
} from '@app/translation';
import { EditTeamMemberWarningComponent } from '@app/pages/teammembers/edit-team-member-warning/edit-team-member-warning.component';

@Component({
	selector: 'app-edit-team-member-profile',
	templateUrl: './edit-team-member-profile.component.html',
	moduleId: module.id,
	styleUrls: ['./edit-team-member-profile.component.scss'],
})
export class EditTeamMemberProfileComponent {
	DATA_STORAGE_UNIT = DataStorageUnit.MEGABYTE;

	@ViewChild('file_input', { static: true }) fileInput: ElementRef;
	@ViewChild('snackbar_anchor', { static: true }) snackbarAnchor: ElementRef;
	imagePreview: any;
	formGroup: FormGroup;
	saving: boolean = false;
	openedOwnProfile: boolean;
	adminOpenedOther: boolean;
	selectedLanguage: string;
	user: IUser;
	isLanguageSwitcherEnabled: boolean = false;
	initialFirstName: string;
	initialLastName: string;
	initialEmail: string;
	secondaryInputType: string = InputStyleTypes.SECONDARY;
	isSAML: boolean;
	isBrazilianAccount: boolean;
	isSipTrunkingApp: boolean;
	supportedFileTypes: string[] = ['.png', '.jpg', '.jpeg'];
	fileUploaderLabel: string;

	public languages$: Observable<IDropdownOption[]> = this.featureFlags.getAllCommonFeatureFlags().pipe(
		map(availableFeatures => {
			return SUPPORTED_LANGUAGE_VALUES_FOR_SELECTION.filter(language => {
				const featureFlag = SUPPORTED_LANGUAGES_FEATURE_FLAGS[language];
				return !featureFlag || availableFeatures[featureFlag];
			}).map(language => {
				return { title: this.translate.instant(`COMMON_NGX.LANGUAGES.${language}`), value: language };
			});
		}),
	);

	private INVALID_IMAGE_TEXT: string = this.translate.instant('PROFILE_PAGE.ONLY_IMAGE_FILES');
	private UNSUPPORTED_IMAGE_TEXT: string = this.translate.instant('GLOBALS.MOH.FILE_WRONG_FORMAT', {
		formats: this.supportedFileTypes?.join(', ') || '',
	});
	private FAILED_IMAGE_UPLOAD_TEXT: string = this.translate.instant('PROFILE_PAGE.FAILED_TO_UPLOAD');

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private fb: FormBuilder,
		private dialogService: DialogService,
		private cService: CommonService,
		private promptService: PromptService,
		private snackbarService: SnackbarService,
		private apiUsers: ApiUsersService,
		private translate: TranslateService,
		private authStorageDataService: AuthStorageData,
		private featureFlags: FeatureFlagsService,
		private jwtService: JwtService,
		private samlService: SamlService,
		private accountsAPI: ApiAccountsService,
	) {
		this.isSipTrunkingApp = this.jwtService.hasPolicy('siptrunk.account.only');
		this.isSAML = this.jwtService.isSAML();
		this.samlService.fetchConfig().subscribe(({ isEnabled }) => {
			this.isSAML = isEnabled;
		});
		this.user = this.data;
		this.openedOwnProfile = Number(this.user.userId) === Number(cService.userId);
		//you can only delete a teammember if
		//a.  you are an admin and
		//b.  you aren't trying to delete yourself
		this.adminOpenedOther = cService.isAdmin() && !this.openedOwnProfile;
		this.featureFlags.languageSwitcher().subscribe((enabled: boolean) => (this.isLanguageSwitcherEnabled = enabled));

		this.formGroup = this.fb.group({
			id: [this.user.userId],
			firstName: [this.user.firstName, [Validators.required, Validators.maxLength(30)]],
			lastName: [this.user.lastName, [Validators.required, Validators.maxLength(30)]],
			email: new FormControl(this.user.email, [
				Validators.required,
				Validators.maxLength(100),
				Validators.pattern(emailRegex),
			]),
			serviceAddress: this.user.serviceAddressId,
			timezone: this.user.timeZone,
		});

		this.initialFirstName = this.formGroup.get('firstName').value;
		this.initialLastName = this.formGroup.get('lastName').value;

		this.initialEmail = this.formGroup.get('email').value;
		this.selectedLanguage = this.user.uiLanguageCode;

		this.accountsAPI.account$.subscribe(value => {
			this.isBrazilianAccount = value.country === 'BR';
		});
		this.fileUploaderLabel = this.translate.instant('PROFILE_PAGE.DRAG_AND_DROP');
	}

	get selectedLanguageOption(): string[] {
		return [this.selectedLanguage];
	}

	get firstName(): AbstractControl {
		return this.formGroup.get('firstName');
	}

	get lastName(): AbstractControl {
		return this.formGroup.get('lastName');
	}

	get isSaveFormDisabled(): boolean {
		return this.formGroup.invalid || this.formGroup.pristine;
	}

	setFirstName(newName: string): void {
		this.formGroup.controls.firstName.setValue(newName);
		this.formGroup.controls.firstName.markAsDirty();
	}

	setLastName(newName: string): void {
		this.formGroup.controls.lastName.setValue(newName);
		this.formGroup.controls.lastName.markAsDirty();
	}

	validateFirstName(): string {
		if (this.firstName.invalid) {
			if (this.firstName.errors.maxlength) {
				return this.translate.instant('PROFILE_PAGE.FIRST_NAME_TOO_LONG');
			}

			if (this.firstName.errors.required) {
				return this.translate.instant('PROFILE_PAGE.FIRST_NAME_REQUIRED');
			}
		}

		return '';
	}

	validateLastName(): string {
		if (this.lastName.invalid) {
			if (this.lastName.errors.maxlength) {
				return this.translate.instant('PROFILE_PAGE.LAST_NAME_TOO_LONG');
			}

			if (this.lastName.errors.required) {
				return this.translate.instant('PROFILE_PAGE.LAST_NAME_REQUIRED');
			}
		}

		return '';
	}

	get email(): AbstractControl {
		return this.formGroup.get('email');
	}

	setEmail(newEmail: string): void {
		this.formGroup.controls.email.setValue(newEmail);
		this.formGroup.controls.email.markAsDirty();
	}

	validateEmail(): string {
		if (this.email.invalid) {
			if (this.email.errors.maxLength) {
				return this.translate.instant('PROFILE_PAGE.EMAIL_TOO_LONG');
			}

			if (this.email.errors.pattern) {
				return this.translate.instant('VALIDATION_ERRORS.EMAIL_INVALID');
			}

			if (this.email.errors.emailAlreadyInUse) {
				return this.translate.instant('VALIDATION_ERRORS.EMAIL_ALREADY_IN_USE');
			}

			if (this.email.errors.required) {
				return this.translate.instant('VALIDATION_ERRORS.EMAIL_REQUIRED');
			}
		}

		return '';
	}

	findItemIndex(selected: Array<any>, clickedItem: any): number {
		return selected.findIndex(i => i.text === clickedItem.text);
	}

	languageChanged(selectedData: IDropdownOption): void {
		this.selectedLanguage = selectedData.value.toString();
		this.formGroup.markAsDirty();
	}

	close = (): void => {
		this.dialogRef.dismiss();
	};

	async validateBeforeSave(): Promise<void> {
		if (this.initialEmail !== this.email.value) {
			this.showConfirmSave();
		} else {
			await this.save();
		}
	}

	async save(): Promise<any> {
		this.saving = true;

		if (this.imagePreview) {
			const avatars = await this.saveAvatar();
			if (this.data.userInfo) {
				this.data.userInfo.avatars = avatars;
			}
			this.data.avatars = avatars;
		}

		const serviceAddressId = this.formGroup.get('serviceAddress').value;
		const email = this.formGroup.get('email').value;

		const timezone = this.formGroup.get('timezone').value;

		const firstName = this.formGroup.get('firstName').value.trim();
		const lastName = this.formGroup.get('lastName').value.trim();

		const body = {
			email,
			firstName,
			lastName,
			serviceAddressId,
			timezone,
			uiLanguageCode: this.selectedLanguage,
		};

		this.apiUsers.updateUser(this.data.userId, body).subscribe((result: IRegularApiResponse<IUser>) => {
			this.saving = false;
			if (!result.hasError) {
				this.authStorageDataService.timezone = timezone;
				this.data.firstName = firstName;
				this.data.lastName = lastName;
				this.data.serviceAddressId = serviceAddressId;
				this.data.email = email;
				this.data.timeZone = timezone;
				this.data.uiLanguageCode = this.selectedLanguage;

				this.dialogRef.dismiss(this.data);

				const currentUserId = parseInt(this.authStorageDataService.userId);

				if (currentUserId === this.data.userId && this.selectedLanguage !== this.translate.currentLang) {
					this.translate.use(this.selectedLanguage);
					location.reload();
				}
				this.snackbarService.create({
					status: 'success',
					text: this.translate.instant('PROFILE_PAGE.PROFILE_INFORMATION_SAVED'),
				});
			} else {
				result.errorMessages.forEach(error => {
					if (error.code === 409) {
						this.email.setErrors({ emailAlreadyInUse: true });
					}
				});

				if (this.formGroup.valid) {
					this.formGroup.setErrors({ invalid: true });
				}
			}
		});
	}

	setUserProfileImage = (file: any): void => {
		const validatationState = this.selectedFileIsValidImage(file);

		if (validatationState.isValid) {
			const reader = new FileReader();

			reader.onload = (result: any): void => {
				this.imagePreview = result.target.result;
			};

			reader.readAsDataURL(file);

			this.formGroup.markAsDirty();
		} else {
			this.snackbarService.create({
				status: 'danger',
				text: validatationState.invalidTypeMessage || validatationState.invalidSizeMessage,
				connectTo: this.snackbarAnchor,
			});
		}
	};

	selectedFileIsValidImage(
		file: any,
	): {
		isValid: boolean;
		invalidTypeMessage: string;
		invalidSizeMessage: string;
	} {
		const validType = /image\/(png|jpg|jpeg)/;
		const validSize = 4 * 1024 * 1024;

		const isValidType = validType.test(file.type);
		const isValidSize = file.size <= validSize;

		return {
			isValid: isValidType && isValidSize,
			invalidTypeMessage: isValidType ? '' : this.UNSUPPORTED_IMAGE_TEXT,
			invalidSizeMessage: isValidSize ? '' : this.INVALID_IMAGE_TEXT,
		};
	}

	onFileChange(file: File | null): void {
		if (!file) {
			this.imagePreview = undefined;
			return;
		}

		this.setUserProfileImage(file);
	}

	saveAvatar(): Promise<any> {
		const { imagePreview, snackbarService, snackbarAnchor, FAILED_IMAGE_UPLOAD_TEXT } = this;

		if (!imagePreview) {
			return;
		}

		// if imagePreview exists, it is valid and we can upload it
		const base64Img = imagePreview.split(',')[1];

		return new Promise((resolve, reject): void => {
			this.apiUsers.insertUserAvatar(this.data.userId, base64Img, 'image/jpeg').subscribe(
				result => resolve(result.data.data.uploaded),
				error => {
					snackbarService.create({
						status: 'danger',
						text: FAILED_IMAGE_UPLOAD_TEXT,
						connectTo: snackbarAnchor,
					});
					reject(error);
				},
			);
		});
	}

	showChangePassword(): void {
		if (!this.openedOwnProfile && !this.adminOpenedOther) return;

		const dialogRef = this.dialogService.create(
			this.adminOpenedOther ? AdminResetPasswordComponent : UpdatePasswordComponent,
			this.data,
			{
				width: 510,
				height: this.adminOpenedOther ? 326 : 624,
			},
		);
		dialogRef.onDismiss().subscribe(result => {
			if (result && !result.cancelled) {
				this.snackbarService.create({
					status: result.error ? 'danger' : 'success',
					text: result.error
						? this.translate.instant('PROFILE_PAGE.PASS_UPDATE_FAILED')
						: this.adminOpenedOther
						? this.translate.instant('ADMIN_RESET_PASSWORD.PASS_RESET_SUCCESSFULLY', {
								name: `${this.data.firstName} ${this.data.lastName}`,
						  })
						: this.translate.instant('PROFILE_PAGE.PASS_UPDATED_SUCCESSFULLY'),
					connectTo: this.snackbarAnchor,
				});
			}
		});
	}

	showDeleteUser(): void {
		const dialogRef = this.promptService.create(DeleteTeamMemberComponent, this.data, {
			width: 510,
			successLabel: this.translate.instant('PROFILE_PAGE.DELETE'),
			successType: 'delete',
		});

		const userId = this.data.userId;

		dialogRef.onDismiss().then(decision => {
			if (decision === Decision.CANCEL) return;
			this.data.status = 'D';
			this.dialogRef.dismiss(this.data);
		});
	}

	async showConfirmSave(): Promise<void> {
		if (this.openedOwnProfile) {
			await this.save();
			return;
		}

		const dialogRef = this.promptService.create(
			EditTeamMemberWarningComponent,
			{},
			{
				width: 510,
				successLabel: this.translate.instant('PROFILE_PAGE.SAVE'),
				successType: 'delete',
			},
		);

		dialogRef.onDismiss().then(async decision => {
			if (decision === Decision.CANCEL) return;
			await this.save();
		});
	}

	isESLanguage(): boolean {
		return localStorage.getItem(UI_LANGUAGE_STORAGE_KEY) === 'es';
	}
}
