import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { ApiAccountsService, FeatureFlagsService, JwtService } from '@app/services';
import { IUserRole } from '@app/services/web-apis/users/api-users.domain';
import { SideNavRoute } from '@app/shared/left-nav-container/left-nav-container.domain';
import { Subscription } from 'rxjs/Subscription';
import { map, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

@Component({
	selector: 'company',
	templateUrl: './company.component.html',
	styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit, OnDestroy {
	sideNavRoutes: SideNavRoute[];

	private readonly ROUTE_PATHS: any = {
		TEAM_MEMBERS: 'teammembers',
		DEPARTMENTS: 'departments',
		RING_GROUPS: 'ringgroups',
		WELCOME_MENUS: 'welcomemenus',
		SPECIAL_EXTENSIONS: 'specialextensions',
		PHONE_NUMBERS: 'phonenumbers',
		PORTS: 'ports',
		CALL_BLOCKING: 'call-blocking',
		OUTBOUND_CALL_BLOCKING: 'outbound-call-blocking',
		COMPANY_DIRECTORY: 'directory',
		DEVICES: 'devices',
		COMPANY_PROFILE: 'companyprofile',
		VIRTUAL_FAX: 'virtual-fax',
		DELEGATION: 'delegation',
		DEVICE_MANAGEMENT: 'device-management',
	};
	private subscription: Subscription;
	private isImpersonated: boolean = false;
	private isSipTrunkingApp: boolean = false;

	constructor(
		private translate: TranslateService,
		private featureFlags: FeatureFlagsService,
		private accountService: ApiAccountsService,
		private jwtService: JwtService,
	) {
		this.isImpersonated = this.jwtService.isImpersonated();
		this.isSipTrunkingApp = this.jwtService.hasPolicy('siptrunk.account.only');
	}

	ngOnInit(): void {
		this.setSideNavRoutes();
		this.subscription = this.translate.onLangChange.subscribe(() => {
			this.setSideNavRoutes();
		});
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	setSideNavRoutes(): void {
		this.translate.get('LEFT_NAVBAR.COMPANY_BAR').subscribe((COMPANY_BAR: any) => {
			const sideNavRoutes = [
				{
					name: COMPANY_BAR.TEAM_MEMBERS,
					routes: [this.ROUTE_PATHS.TEAM_MEMBERS],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.DEPARTMENTS,
					routes: [this.ROUTE_PATHS.DEPARTMENTS],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.RING_GROUPS,
					routes: [this.ROUTE_PATHS.RING_GROUPS],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.WELCOME_MENUS,
					routes: [this.ROUTE_PATHS.WELCOME_MENUS],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.SPECIAL_EXTENSIONS,
					routes: [this.ROUTE_PATHS.SPECIAL_EXTENSIONS],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.PHONE_NUMBERS,
					routes: [this.ROUTE_PATHS.PHONE_NUMBERS],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.PORTS,
					routes: [this.ROUTE_PATHS.PORTS],
					requiredRole: IUserRole.ADMIN,
					enabled: false,
				},
				{
					name: COMPANY_BAR.VIRTUAL_FAX,
					routes: [this.ROUTE_PATHS.VIRTUAL_FAX],
					requiredRole: IUserRole.ADMIN,
					enabled: false,
				},
				{
					name: COMPANY_BAR.COMPANY_DIRECTORY,
					routes: [this.ROUTE_PATHS.COMPANY_DIRECTORY],
					requiredRole: IUserRole.ADMIN,
					enabled: true,
				},
				{
					name: COMPANY_BAR.CALL_BLOCKING,
					routes: [this.ROUTE_PATHS.CALL_BLOCKING],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
				{
					name: COMPANY_BAR.OUTBOUND_CALL_BLOCKING,
					routes: [this.ROUTE_PATHS.OUTBOUND_CALL_BLOCKING],
					requiredRole: IUserRole.ADMIN,
					enabled: false,
				},
				{
					name: COMPANY_BAR.DEVICES,
					routes: [this.ROUTE_PATHS.DEVICES],
					requiredRole: IUserRole.ADMIN,
					enabled: true,
				},
				{
					name: COMPANY_BAR.DEVICE_MANAGEMENT,
					routes: [this.ROUTE_PATHS.DEVICE_MANAGEMENT],
					requiredRole: IUserRole.REGULAR,
					enabled: false,
				},
				{
					name: COMPANY_BAR.DELEGATION,
					routes: [this.ROUTE_PATHS.DELEGATION],
					requiredRole: IUserRole.ADMIN,
					enabled: false,
				},
				{
					name: COMPANY_BAR.COMPANY_PROFILE,
					routes: [this.ROUTE_PATHS.COMPANY_PROFILE],
					requiredRole: IUserRole.REGULAR,
					enabled: true,
				},
			];

			this.sideNavRoutes = this.isSipTrunkingApp
				? sideNavRoutes
						.filter(({ name }) => name === COMPANY_BAR.DELEGATION)
						.map(route => ({
							...route,
							enabled: true,
						}))
				: sideNavRoutes;
		});

		this.getFeatureFlags();
		this.checkClaims();
	}

	private getFeatureFlags(): void {
		this.featureFlags.outboundCallBlockingEnabled().subscribe(enabled => {
			this.setEnabled(this.ROUTE_PATHS.OUTBOUND_CALL_BLOCKING, enabled);
		});

		this.featureFlags.isVirtualFaxEnabled().subscribe(enabled => {
			this.setEnabled(this.ROUTE_PATHS.VIRTUAL_FAX, enabled);
		});

		this.featureFlags.isDelegationEnabled().subscribe(enabled => {
			this.setEnabled(this.ROUTE_PATHS.DELEGATION, enabled);
		});

		this.featureFlags.isDeviceManagementEnabled().subscribe(enabled => {
			this.setEnabled(this.ROUTE_PATHS.DEVICE_MANAGEMENT, enabled);
		});

		combineLatest([this.featureFlags.porting(), this.accountService.isDemoAccount().pipe(take(1))])
			.pipe(
				map(([isPortingFeatureEnabled, isDemoAccount]) => {
					return isPortingFeatureEnabled && !isDemoAccount;
				}),
			)
			.subscribe(enabled => {
				this.setEnabled(this.ROUTE_PATHS.PORTS, enabled);
			});
	}

	private checkClaims(): void {
		this.setEnabled(this.ROUTE_PATHS.DELEGATION, !this.isImpersonated); // Impersonation is not allowed
	}

	private setEnabled(key: string, enabled: boolean): void {
		const route = this.sideNavRoutes.find(snr => snr.routes.includes(key));
		if (route) {
			route.enabled = enabled;
		}
	}
}
