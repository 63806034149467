import { Directive, ElementRef, EventEmitter, HostListener, Injector, Input, OnInit, Output } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subject } from 'rxjs/Subject';

import { POPUP_ADDITIONAL_DATA, POPUP_CLICK_CALLBACK, POPUP_DATA, POPUP_REF } from '@n2p/popup/popup.injectors';
import { GoogleAnalyticsService } from '@app/services/google-analytics';
import { IGAEvent } from '@app/services/google-analytics/google-analytics.domain';

@Directive({
	selector: '[n2p-header-option-btn]',
})
export class HeaderOptionBtnDirective implements OnInit {
	@Input('n2p-header-option-btn') popupComponent: any;
	@Input() popUpOpenSubject: Subject<any>;
	@Input() toggleDialPad: any;
	@Input() showDialer: any;
	@Input() offsetX: number = 0;
	@Input() offsetY: number = 5;
	@Input() googleEvent: IGAEvent;
	@Output() closed: EventEmitter<any> = new EventEmitter();
	overlayRef: OverlayRef;
	private _data: any;
	private needToHideOnClose: boolean = true;
	constructor(
		private overlay: Overlay,
		private el: ElementRef,
		private injector: Injector,
		private googleAnalyticsService: GoogleAnalyticsService,
	) {}

	@Input() set data(d: any) {
		this._data = d;
	}

	get data(): any {
		return this._data;
	}

	@HostListener('click', ['$event']) onClick(ev: any): void {
		this.googleEvent && this.googleAnalyticsService.event(this.googleEvent);
		if (this.overlayRef) {
			this.close();
		} else {
			this.popUpOpenSubject.next();
			this.overlayRef = this.overlay.create({
				hasBackdrop: false,
				backdropClass: '',
				scrollStrategy: this.overlay.scrollStrategies.reposition(),
				positionStrategy: this.overlay
					.position()
					.connectedTo(
						this.el,
						{
							originY: 'bottom',
							originX: 'end',
						},
						{
							overlayY: 'top',
							overlayX: 'end',
						},
					)
					.withOffsetX(this.offsetX)
					.withOffsetY(this.offsetY),
			});
			this.overlayRef.backdropClick().subscribe(() => {
				this.close();
			});
			const portal = new ComponentPortal(this.popupComponent, undefined, this.createInjector());
			this.overlayRef.attach(portal);
			this.el.nativeElement.classList.add('active');

			// Workaround for https://idtjira.atlassian.net/browse/UI-851
			// We need to close correspond popup component on outside click but not right after opening
			// Also we do not use 'ev.stopPropagation()' to close dialer when an user clicks to this header button
			this.needToHideOnClose = false;
			setTimeout(() => {
				this.needToHideOnClose = true;
			});
		}
	}

	ngOnInit(): void {
		this.popUpOpenSubject.subscribe(() => this.close());
	}

	private close = (action?: any): void => {
		if (!this.overlayRef || !this.needToHideOnClose) return;
		this.overlayRef.dispose();
		this.overlayRef = undefined;
		this.el.nativeElement.classList.remove('active');
		this.closed.emit(action);
	};

	private createInjector(): PortalInjector {
		const injectorTokens = new WeakMap();
		injectorTokens.set(POPUP_CLICK_CALLBACK, action => this.close(action));
		injectorTokens.set(POPUP_REF, this.overlayRef);
		injectorTokens.set(POPUP_DATA, this.data || null);
		injectorTokens.set(POPUP_ADDITIONAL_DATA, {
			toggleDialPad: this.toggleDialPad,
			showDialer: this.showDialer,
		});

		return new PortalInjector(this.injector, injectorTokens);
	}
}
