import { Component, ElementRef, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Overlay } from '@angular/cdk/overlay';
import { cloneDeep, isEqual } from 'lodash/fp';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subscription } from 'rxjs';
import { DataStorageUnit } from 'n2p-ui-library';

import {
	SnackbarService,
	WelcomeMenuService,
	FeatureFlagsService,
	AuthStorageData,
	DialogService,
} from '@app/services';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';
import { FileSizeLevels } from '@app/Common';
import { WelcomeMenuTtsComponent } from '@app/pages/welcomemenu/addwelcomemenu/welcome-menu-tts/welcome-menu-tts.component';
import { TtsDialogData } from '@app/pages/welcomemenu/welcomemenu.components.domain';

@Component({
	selector: 'app-greeting',
	templateUrl: './greeting.component.html',
	styleUrls: ['./greeting.component.scss'],
})
export class GreetingComponent implements OnInit, OnDestroy {
	DATA_STORAGE_UNIT = DataStorageUnit.MEGABYTE;

	@ViewChild('greetingViaPhoneBtn', { static: true }) greetingViaPhoneBtn: ElementRef;
	@Input() formGroup: FormGroup;
	subscriptions: Subscription = new Subscription();
	greetingTextToSpeech = false;
	edit = false;
	audioIsPlaying = false;
	makingTheCall = false;
	isAudioLoading = true;
	uploadingGreeting = false;
	menuOptionsUpdated = false;
	initialOptions: any;
	isCallServerV3User: boolean;
	isEnableRecordViaPhone30: boolean;

	CUSTOM_GREETING_FILE_SIZE: number = 20 * FileSizeLevels.MEGABYTE;

	constructor(
		private welcomeMenuService: WelcomeMenuService,
		private snackbarService: SnackbarService,
		private overlay: Overlay,
		private injector: Injector,
		private translate: TranslateService,
		private featureFlagsService: FeatureFlagsService,
		private apiMusicOptionsService: ApiMusicOptionsService,
		private authStorageDataService: AuthStorageData,
		private dialogService: DialogService,
	) {}

	ngOnInit(): void {
		this.isCallServerV3User = this.authStorageDataService.isV3CallServer;
		this.isCallServerV3User && this.formGroup.get('greeting').setValue('three');
		forkJoin([
			this.featureFlagsService.enableRecordViaPhone30(),
			this.featureFlagsService.greetingTextToSpeech(),
		]).subscribe(res => {
			this.isEnableRecordViaPhone30 = res[0];
			this.greetingTextToSpeech = res[1];
		});
		this.initialOptions = cloneDeep(this.formGroup.get('options').value.sort(this.sortOptions));

		this.subscriptions.add(
			this.formGroup.get('greetingBase64').valueChanges.subscribe(value => {
				if (value) {
					this.isAudioLoading = false;
				}
			}),
		);

		this.subscriptions.add(
			this.formGroup.get('options').valueChanges.subscribe(value => {
				value = value.sort(this.sortOptions);
				this.menuOptionsUpdated = !isEqual(value, this.initialOptions);
			}),
		);
	}

	ngOnDestroy() {
		this.subscriptions.unsubscribe();
	}

	async fileChanged(ev): Promise<void> {
		if (!ev.target.files || !ev.target.files[0]) {
			return;
		}

		const file = ev.target.files[0];

		await this.uploadCustomFile(file);
	}

	async handleFileDrop(file: File): Promise<void> {
		if (!file) return;

		await this.uploadCustomFile(file);
	}

	handleFileError(): void {
		this.snackbarService.create({
			text: this.translate.instant('GREETING.NOT_UPLOADED'),
			status: 'danger',
			connectTo: this.formGroup.get('footer').value,
		});
	}

	async uploadCustomFile(file: File): Promise<any> {
		if (this.checkCustomGreetingFile(file)) {
			this.isAudioLoading = true;
			this.uploadingGreeting = true;
			const formData = new FormData();
			const id = this.formGroup.get('id').value;
			formData.append('OwnerType', 'multiAttendants');
			formData.append('OwnerId', id.toString());
			formData.append('Type', 'menuGreetingFiles');
			formData.append('Content', file);

			this.apiMusicOptionsService.uploadMediaFormData(formData).subscribe(res => {
				if (res.hasError) {
					this.showUploadFailure();
				} else {
					this.formGroup.get('greetingFile').setValue(file);
					this.formGroup.get('transcription').setValue('');
					this.formGroup.get('greetingBase64').setValue('');
					this.formGroup.get('greeting').setValue('three');

					this.showUploadSuccess();
				}

				this.isAudioLoading = false;
				this.uploadingGreeting = false;
			});
		}
	}

	callMe(phoneNumber): void {
		this.makingTheCall = true;
		this.welcomeMenuService.uploadGreeting(this.formGroup.get('id').value, 3, '', phoneNumber).subscribe(res => {
			if (!res.data) {
				this.showUploadFailure();
			}
			const interval = setInterval(() => {
				this.welcomeMenuService
					.checkOnGreeting(this.formGroup.get('id').value, res.data.recordingId)
					.subscribe(statusRes => {
						if (statusRes.data === 'R') {
							clearInterval(interval);
							this.welcomeMenuService.getGreeting(this.formGroup.get('id').value).subscribe(r => {
								const base64 = `data:audio/wav;base64,${r.data[0].file}`;
								this.formGroup.get('greetingBase64').setValue(base64);
								this.formGroup.get('greeting').setValue('two');
							});
							this.showUploadSuccess();
							this.makingTheCall = false;
						} else if (statusRes.data === 'F' || statusRes.data === 'B') {
							this.makingTheCall = false;
							clearInterval(interval);
							this.showUploadFailure();
						}
					});
			}, 3000);
		});
	}

	get audioFile(): undefined | string | File {
		const fileControl = this.formGroup.get('greetingFile');
		const base64Control = this.formGroup.get('greetingBase64');

		if (fileControl && fileControl.value) {
			return fileControl.value;
		}

		if (base64Control && base64Control.value) {
			return base64Control.value;
		}
	}

	private sortOptions = (opt1, opt2): number => {
		return opt1.key.toString() < opt2.key.toString() ? 1 : -1;
	};

	private showUploadSuccess(): void {
		this.snackbarService.create({
			text: this.translate.instant('GREETING.UPLOADED'),
			status: 'success',
			connectTo: this.formGroup.get('footer').value,
		});
	}

	private showUploadFailure(): void {
		this.snackbarService.create({
			text: this.translate.instant('GREETING.NOT_SAVED'),
			status: 'danger',
			connectTo: this.formGroup.get('footer').value,
		});
	}

	public openTtsDialog(): void {
		// if this.formGroup.get('transcription').value exist it means that active Greeting is from transcription
		const greetingBase64 = this.formGroup.get('transcription').value && this.formGroup.get('greetingBase64').value;

		const dialogData: TtsDialogData = {
			id: this.formGroup.get('id').value,
			transcription: this.formGroup.get('transcription').value,
			greetingBase64: greetingBase64,
			options: this.formGroup.get('options').value,
		};
		const ttsRef = this.dialogService.create(WelcomeMenuTtsComponent, dialogData, { width: 510 });

		const ttsSubscription = ttsRef.onDismiss().subscribe(result => {
			if (result) {
				this.formGroup.get('transcription').setValue(result.transcription);
				this.formGroup.get('greetingFile').setValue(null);
				this.formGroup.get('greetingBase64').setValue(result.greetingBase64);
				this.formGroup.get('greeting').setValue('four');
			}
		});

		this.subscriptions.add(ttsSubscription);
	}

	private checkCustomGreetingFile(file: File): boolean {
		const fileExt = file.name.substr(file.name.lastIndexOf('.'));
		const fileTooLarge = file.size > this.CUSTOM_GREETING_FILE_SIZE;
		const fileUnsupported = fileExt !== '.mp3' && fileExt !== '.m4a' && fileExt !== '.wav';
		if (fileTooLarge || fileUnsupported) {
			this.snackbarService.create({
				text: fileTooLarge ? this.translate.instant('GREETING.LARGE') : this.translate.instant('GREETING.UNSUPPORTED'),
				status: 'danger',
				connectTo: this.formGroup.get('footer').value,
			});
			return false;
		}
		return true;
	}
}
