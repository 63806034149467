import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { take, filter } from 'rxjs/operators';

import {
	ApiAccountsService,
	ApiUsersService,
	ApiVoicemailsService,
	AuthService,
	CommonService,
	DeviceServices,
	FeatureFlagsService,
	GlobalEventsService,
	AuthStorageData,
	UserAgentService,
	ProfileSettingsService,
	JwtService,
} from '@app/services';
import { environment } from '@env/environment';
import { HelpSupportPopupComponent } from '@n2p/header/help-support-popup/help-support-popup.component';
import { VoiceMailPopupComponent } from '@n2p/header/voice-mail-popup/voice-mail-popup.component';
import { FeaturesInfo } from '@app/services/feature-flags/features.domain';
import { getAvatarUrlFromData, isDescendantNode } from '@utils/helpers/functions';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { RCON_ROUTE } from '@app/Common';
import { ConstantsService } from '@app/services/constants/constants.service';
import {
	GoogleAnalyticsService,
	TOP_NAVIGATION_HELP,
	TOP_NAVIGATION_HUDDLE,
	TOP_NAVIGATION_RCON,
	TOP_NAVIGATION_USER_PROFILE,
	TOP_NAVIGATION_VOICEMAIL,
	TOP_NAVIGATION_WALLBOARD,
	TOP_NAVIGATION_WEB_DIALER,
} from '@app/services/google-analytics';
import { IGAEvent } from '@app/services/google-analytics/google-analytics.domain';
import { EditTeamMemberComponent } from '@app/pages/teammembers/edit-team-member/edit-team-member.component';
import { DialogService } from '@n2p';
import { ENV } from '@env/environment.domain';

@Component({
	selector: 'n2p-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
	@ViewChild('rconCheckbox', { static: false }) rconCheckbox: ElementRef;

	user: IUser;
	voiceMails: any[];
	voiceMailsUnreadCounter: number;
	countryFeatureToggle: FeaturesInfo = {};
	voiceMailPopupComponent: typeof VoiceMailPopupComponent = VoiceMailPopupComponent;
	helpSupportPopupComponent: typeof HelpSupportPopupComponent = HelpSupportPopupComponent;
	popUpOpenSubject: Subject<void> = new Subject();
	private subscription: Subscription = new Subscription();

	isPremiumBannerVisible = false;
	isSidebarVisible = false;
	isTermsChecked = false;
	isSPOGSwitcherEnabled: boolean;
	isCallServerV3User: boolean;
	showSuppressedItemsEnabled: boolean;
	dialerEnabled: boolean = false;
	rconOpen: boolean = false;
	showRconPopup: boolean = false;
	huddleUrl: string = environment.huddleUrl;
	wallboardUrl: string = environment.wallboard_url;
	isDialerConnected: boolean = false;
	isPamConsole: boolean = environment.is_pam_console;
	isAdminApp: boolean = environment.is_admin_app;
	isAppMode: boolean = this.constantsService.isAppMode();
	isSipTrunkingApp: boolean = false;
	isSupervisorLicenseEnabled: boolean = false;

	googleEvents: Record<string, IGAEvent> = {
		huddle: TOP_NAVIGATION_HUDDLE,
		rcon: TOP_NAVIGATION_RCON,
		dialer: TOP_NAVIGATION_WEB_DIALER,
		voicemail: TOP_NAVIGATION_VOICEMAIL,
		help: TOP_NAVIGATION_HELP,
		profile: TOP_NAVIGATION_USER_PROFILE,
		wallboard: TOP_NAVIGATION_WALLBOARD,
	};

	constructor(
		private authStorageDataService: AuthStorageData,
		private jwtService: JwtService,
		private apiUserService: ApiUsersService,
		private apiAccountService: ApiAccountsService,
		private apiVoiceMailsService: ApiVoicemailsService,
		private deviceService: DeviceServices,
		private cService: CommonService,
		private featureFlags: FeatureFlagsService,
		private profileSettingsService: ProfileSettingsService,
		private globalEventsService: GlobalEventsService,
		private router: Router,
		private userAgentService: UserAgentService,
		private authService: AuthService,
		private constantsService: ConstantsService,
		private googleAnalyticsService: GoogleAnalyticsService,
		private dialogService: DialogService,
	) {
		this.isSipTrunkingApp = this.jwtService.hasPolicy('siptrunk.account.only');
		this.isSupervisorLicenseEnabled = this.jwtService.hasPolicy('callqueue.supervisor');
		this.featureFlags.spogSwitcher().subscribe((enabled: boolean) => (this.isSPOGSwitcherEnabled = enabled));
		this.featureFlags.unitedStatesLogo().subscribe((enabled: boolean) => (this.countryFeatureToggle.USLogo = enabled));
		this.featureFlags.brazilLogo().subscribe((enabled: boolean) => (this.countryFeatureToggle.BrazilLogo = enabled));
		this.featureFlags.calaLogo().subscribe((enabled: boolean) => (this.countryFeatureToggle.CalaLogo = enabled));
		this.apiUserService.user$.subscribe(newUserData => {
			if (this.user?.userId === newUserData.userId) {
				this.user = newUserData;
			}
		});
	}

	get showDialer(): boolean {
		return this.dialerEnabled && !this.rconOpen && !!this.user;
	}

	get dialerTooltipText(): string {
		let text = '';
		if (this.rconOpen) {
			text = 'HEADER.RCON_OPEN_DISABLE_MSG';
		} else if (!this.showDialer) {
			text = 'HEADER.DIAL_PAD_DISABLE_MSG';
		} else if (this.showDialer && !this.isDialerConnected) {
			text = 'HEADER.DIALER_RECONNECTING';
		}

		return text;
	}

	get shouldRconBeHidden(): boolean {
		if (this.isPamConsole) return true;
		if (this.isSipTrunkingApp) return true;
		if (!this.userAgentService.isBrowser()) return true;
		if (!this.cService.webRtcEnabled) return true;
		if (this.isOnRconPage) return true;
		if (this.jwtService.hasScope('impersonate')) return !this.jwtService.hasScope('webrtc');

		return !this.jwtService.hasPolicy('receptionistconsole');
	}

	get shouldDialerBeHidden(): boolean {
		if (this.isAdminApp) return true;
		if (this.isPamConsole) return true;
		if (this.isSipTrunkingApp) return true;
		if (!this.userAgentService.isBrowser()) return true;
		if (!this.cService.webRtcEnabled) return true;
		if (this.jwtService.hasScope('impersonate')) return !this.jwtService.hasScope('webrtc');

		return false;
	}

	get shouldHuddleBeHidden(): boolean {
		if (this.isPamConsole) return true;
		if (this.isSipTrunkingApp) return true;
		if (this.jwtService.hasScope('impersonate')) return true;

		return !this.jwtService.hasPolicy('huddle');
	}

	get shouldWallboardBeHidden(): boolean {
		if (this.isPamConsole) return true;
		if (this.isSipTrunkingApp) return true;

		return !this.isSupervisorLicenseEnabled;
	}

	get shouldPremiumUISwitcherBeHidden(): boolean {
		if (this.isPamConsole) return true;
		if (this.isSipTrunkingApp) return true;
		if (this.isAdminApp) return true;

		return false;
	}

	get isOnRconPage(): boolean {
		return this.router.url.includes(RCON_ROUTE);
	}

	get isSPOGPolicyEnabled(): boolean {
		return this.jwtService.hasPolicy('spog') && this.jwtService.hasPolicy('webapplication.preferred.classic');
	}

	handlePremiumBannerClose(): void {
		this.isPremiumBannerVisible = false;
	}

	handleClickProfile(user: IUser): void {
		this.isSidebarVisible = false;
		this.apiAccountService.account$.pipe(take(1)).subscribe(value => {
			this.dialogService.create(
				EditTeamMemberComponent,
				{ ...user, canCreateDidlessUsers: value.canCreateDidlessUsers },
				{ width: 800 },
			);
		});
	}

	signOut(): void {
		this.authService.signOut();
	}

	handleToggleSPOG(): void {
		if (this.isTermsChecked) {
			this.setSPOGPreferredWebApplication();
		} else {
			this.isPremiumBannerVisible = true;
		}
	}

	setSPOGPreferredWebApplication(): void {
		this.profileSettingsService.setSPOGPreferredWebApplication().subscribe(() => {
			this.isTermsChecked = true;
			window.localStorage.setItem('is-redirect-from-webapp', 'true');
			location.reload();
		});
	}

	makeSupportCall = (event: Event, numberToDial: string = ''): void => {
		if (this.cService.webRtcEnabled && this.isDialerConnected) {
			window.dialer && this.globalEventsService.makeCall(numberToDial);
		}
	};

	toggleSipDialPad = (event: Event, numberToDial: string = ''): void => {
		if (!this.showDialer || !this.isDialerConnected) return;
		this.doToggleSipDialPad(numberToDial);
		if (event) event.stopPropagation();
		this.popUpOpenSubject.next();
	};

	voiceMailPopupClosed(): void {
		this.getVoiceMails();
	}

	profilePopupClosed(reloadUser: boolean = false): void {
		if (reloadUser) {
			this.getUser();
		}
	}

	ngOnInit(): void {
		this.isCallServerV3User = this.authStorageDataService.isV3CallServer;
		this.featureFlags.enableCallHistory30().subscribe(res => (this.showSuppressedItemsEnabled = res));
		this.initHeader();

		document.body.addEventListener('click', (event: MouseEvent) => {
			// TODO is this necessary
			const outgoingDialContainer = this.outgoingCallContainer;
			const clickedElement = event.target;
			const userClickedContainer = clickedElement === outgoingDialContainer;
			const userClickedContainerChild = isDescendantNode(outgoingDialContainer, clickedElement);

			if (outgoingDialContainer && !userClickedContainer && !userClickedContainerChild) {
				this.toggleSipDialPad(undefined);
			}

			this.popUpOpenSubject.next();
		});

		this.subscription.add(
			this.cService.toggleDialerSub.subscribe(() => {
				this.dialerEnabled = this.cService.webRtcEnabled;
			}),
		);

		this.subscription.add(
			this.globalEventsService.rconOpenEvent.subscribe(isOpen => {
				this.rconOpen = isOpen;
			}),
		);

		this.subscription.add(
			this.globalEventsService.dialerConnected.subscribe(connected => {
				this.isDialerConnected = connected;
			}),
		);
		this.globalEventsService.initializeStorage('rconOpen');
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}

	private doToggleSipDialPad = (numberToDial: string = ''): void => {
		if (this.cService.webRtcEnabled) {
			window.dialer && window.dialer.toggle();
		}
	};

	private initHeader(): void {
		this.getUser();
		this.getVoiceMails();

		if (!this.shouldDialerBeHidden) {
			this.getWebRtc();
		}
	}

	private getUser(): void {
		this.apiUserService.user$
			.pipe(
				filter(v => !!v),
				take(1),
			)
			.subscribe(res => {
				this.user = res;
				this.getWebRtc();
			});
	}

	private getWebRtc(): void {
		this.deviceService.getWebRtc().subscribe(response => {
			if (!response.hasError) {
				this.cService.webRtcEnabled = response.data && response.data.status === 'A';
				this.dialerEnabled = this.cService.webRtcEnabled;
			}
		});
	}

	private getVoiceMails(): void {
		this.apiVoiceMailsService.getUserVoicemails(true, 20, null, null, 'desc').subscribe(res => {
			this.voiceMails = (res.data || []).sort((a, b) =>
				a.voicemailTime && b.voicemailTime && a.voicemailTime < b.voicemailTime ? 1 : -1,
			);
			if (res.paging && res.paging.totalCount > 0) {
				this.voiceMailsUnreadCounter = res.paging.totalCount;
			} else {
				this.voiceMailsUnreadCounter = this.voiceMails.length;
			}
		});
	}

	get outgoingCallContainer(): any {
		return document.getElementsByClassName('outgoing-call-container dial')[0];
	}

	get dialPadOpened(): boolean {
		return !!this.outgoingCallContainer;
	}

	get initialCheckboxValue(): boolean {
		return !!localStorage.getItem('not-show-rc-popup');
	}

	get avatarUrl(): string {
		return getAvatarUrlFromData(this.user, '120');
	}

	onRconClick(): void {
		this.googleAnalyticsService.event(this.googleEvents.rcon);
		if (localStorage.getItem('not-show-rc-popup') === 'true') {
			this.openRcon();
		} else {
			this.showRconPopup = true;
		}
	}

	handleOpenRc(): void {
		if (this.rconCheckbox.nativeElement.checked) {
			localStorage.setItem('not-show-rc-popup', 'true');
		}
		this.hideRconPopup();
		this.openRcon();
	}

	onHuddleClick(): void {
		this.googleAnalyticsService.event(this.googleEvents.huddle);
	}

	onWallboardClick(): void {
		this.googleAnalyticsService.event(this.googleEvents.wallboard);
	}

	onDialerClick(event: any): void {
		this.googleAnalyticsService.event(this.googleEvents.dialer);
		this.toggleSipDialPad(event);
	}

	hideRconPopup(): void {
		this.showRconPopup = false;
	}

	private openRcon(): void {
		const tempInvisibleLink = document.createElement('a');
		if (this.isAdminApp) {
			tempInvisibleLink.href = ({
				development: 'https://dev-rcon.net2phone.com',
				qa: 'https://qa-rcon.net2phone.com',
				staging: 'https://uat-rcon.net2phone.com',
				production: 'https://rcon.net2phone.com',
			} as Record<ENV, string>)[environment.env];
		} else {
			tempInvisibleLink.href = (environment.base_href || '') + RCON_ROUTE;
		}
		tempInvisibleLink.target = '_blank';
		tempInvisibleLink.click();
		document.removeChild(tempInvisibleLink);
	}

	get iconParams(): { name: string; width: number; height: number } {
		if (this.countryFeatureToggle.BrazilLogo) {
			return {
				name: 'logo-brazil',
				height: 34,
				width: 140,
			};
		}
		if (this.countryFeatureToggle.CalaLogo) {
			return {
				name: 'logo-brazil',
				height: 38,
				width: 198,
			};
		}
		const isOctober = new Date().getMonth() === 9;
		return {
			name: isOctober ? 'logo-us-october' : 'logo-us',
			height: isOctober ? 40 : 29,
			width: 140,
		};
	}
}
