export const supportContactMap: Readonly<Record<
	'MX' | 'AR' | 'BR' | 'US',
	{ email: string; phone: string }
>> = Object.freeze({
	MX: {
		email: 'soporte@net2phone.com',
		phone: '55-4161-4908',
	},
	AR: {
		email: 'soporte@net2phone.com',
		phone: '(011) 5365-9050',
	},
	BR: {
		email: 'suporte@net2phone.com',
		phone: '103-26',
	},
	US: {
		email: 'support@net2phone.com',
		phone: '1 (866) 978-8260',
	},
});
