import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Data, Route, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { DEFAULT_ROUTE } from '@app/Common';
import { LicensesService } from '@app/services';

import { ILicense, LicenseCode } from '@app/services/licenses/licenses.domain';

@Injectable({
	providedIn: 'root',
})
export class LicenseWithPrivateGuard implements CanLoad, CanActivate {
	constructor(
		private licensesService: LicensesService,
		public router: Router,
	) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
		return this.handleRouting(route.data);
	}

	canLoad(route: Route): Observable<boolean> {
		return this.handleRouting(route.data);
	}

	private handleRouting(data: Data): Observable<boolean> {
		const licenseCode: LicenseCode = data.licenseCode;

		if (!Object.keys(this.licenseMap).some(lc => lc === licenseCode)) {
			throw Error(`The type ${licenseCode} doesn't have a mapping into the license code`);
		}

		const source$: Observable<boolean> = this.licenseMap[licenseCode]();

		return source$.map(result => {
			if (!result) this.router.navigate([data.redirectUrl || DEFAULT_ROUTE]);

			return result;
		});
	}

	private isCallQueueEnabled = (): Observable<boolean> => {
		return this.licensesService.getLicensesWithPrivate().pipe(
			map(result => {
				return result.some(license => license.licenseCode === 'customer.callqueue');
			}),
		);
	};

	private isSipTieLineEnabled = (): Observable<boolean> => {
		return this.licensesService.getLicensesWithPrivate().pipe(
			map(result => {
				const license: ILicense | undefined = result.find(license => license.licenseCode === 'tieline.connections');
				return license && LicensesService.checkHasActiveLicenses(license);
			}),
		);
	};

	private licenseMap: Record<LicenseCode, () => Observable<boolean>> = {
		'customer.callqueue': this.isCallQueueEnabled,
		'tieline.connections': this.isSipTieLineEnabled,
	};
}
