import { secondsToTime } from 'n2p-ui-library';
import { ICallRecording } from '@app/services/callhistory/callhistory.domain';

export const generateIdMapFromArray = (idKey: string, array: any[]) => {
	return array && array.length
		? array.reduce((idMap, currentItem) => {
				const itemId = currentItem[idKey];
				idMap[itemId] = currentItem;

				return idMap;
		  }, {})
		: {};
};

export const safeDecimalAdd = (val1, val2) => {
	// coerce values to numbers in case of string format. e.g "0.2"
	const value1 = Number(val1);
	const value2 = Number(val2);

	if (isNaN(value1) || isNaN(value2)) {
		throw Error('Parameters must be valid numbers');
	}

	return (value1 * 100 + value2 * 100) / 100;
};

export const formatMoney = (amount: number, omitSymbol?: boolean) => {
	if (!amount) {
		return '$ 0.00';
	}

	const amt = amount.toString().split('.');
	const leftOfDec = amt[0].split('');
	const rightOfDec = amt[1] ? amt[1].split('') : ['0', '0'];

	if (rightOfDec.length === 1) {
		rightOfDec.push('0');
	}
	const amountReversed = leftOfDec.reverse();

	let result = '';
	for (let i = 0; i < amountReversed.length; i++) {
		// insert comma after every third digit unless the character to be added is a negative
		if (i % 3 === 0 && i !== 0 && amountReversed[i] !== '-') {
			result += ',';
		}
		result += amountReversed[i];
	}
	return `${omitSymbol ? '' : '$'} ${result
		.split('')
		.reverse()
		.join('')}.${rightOfDec.join('')}`;
};

export const formatSecondsToTime = (duration: number | undefined, isFixedHours: boolean = false): string => {
	if (typeof duration !== 'number' || isNaN(duration)) {
		return '';
	}

	return secondsToTime(duration, isFixedHours ? 'HH:mm:ss' : 'HH*:mm:ss');
};

export const getMultiDropDownDataId = type => {
	switch (type) {
		case 'tm':
			return 'userId';
		case 'r':
			return 'id';
		case 'ext':
			return 'id';
		case 'd':
			return 'deptId';
		case 'w':
			return 'id';
		case 'e':
			return 'id';
		default:
			throw Error(`The type ${type} doesn't have a mapping into the multidropdown data id`);
	}
};

export const getMultiDropDownDataType = (type: string) => {
	switch (type) {
		case 'tm':
			return 'user';
		case 'r':
			return 'ringGroup';
		case 'ext':
			return 'phone';
		case 'd':
			return 'department';
		case 'w':
			return 'maa';
		case 'e':
			return 'specialExtension';
		case 'repeat':
			return 'repeat';
		default:
			return type;
	}
};

export const makeTwoDigits = (num: Number): string => {
	return num < 10 ? `0${num}` : num.toString();
};

export const matchSearchString = (searchStr: string, choices: Array<string | number>) => {
	// helper func to make string comparison more lenient/accurate
	const formatStr = str => (str ? `${str}`.toLowerCase().replace(/\s/g, '') : '');

	return choices.some(val => formatStr(val).includes(formatStr(searchStr)));
};

/**
 * A function that groups phone numbers by entity type and optionally applies a transform to each group
 * @param phoneNumbers A list of phone numbers to be grouped by entity type
 * @param transformFunc An optional transform function to be applied to each group
 */
export const groupPhoneNumbersByEntityType = (
	phoneNumbers: Array<any>,
	transformFunc?: (group: Array<any>) => Array<any>,
) => {
	const grouped = [];
	const entities = {
		'': [], // unassigned,
		teamMember: [], // teamMember and user are the same category, this ensures both are in same place in list
		user: [],
		department: [],
		ringGroup: [],
		welcomeMenu: [], // welcome menus have different types in the server, this will ensure menus are in the same place in order
		menu: [],
		maa: [],
		specialExtension: [],
	};
	for (const num of phoneNumbers) {
		const entityType = num.routeType || '';
		const entityArray = entities[entityType] || [];
		entityArray.push(num);
		if (!entities[entityType]) entities[entityType] = entityArray;
	}

	for (const key of Object.keys(entities)) {
		const entityArray = transformFunc ? transformFunc(entities[key]) : entities[key];
		for (const value of entityArray) {
			grouped.push(value);
		}
	}

	return grouped;
};

/**
 * A function that sorts an array of objects by a given property
 * @param prop The prop to sort by
 * @param objects The array of objects to sort
 */
export const sortObjsByProp = <T>(prop: string, objects: Array<T>): Array<T> =>
	objects.sort((a, b) => {
		const aLowerCase = (a[prop] || '').toLowerCase();
		const bLowerCase = (b[prop] || '').toLowerCase();

		return aLowerCase === bLowerCase ? 0 : aLowerCase < bLowerCase ? -1 : 1;
	});

export const getEntityType = (type: string): string => {
	switch (type) {
		case 'user':
			return 'team-member';
		case 'department':
			return 'department';
		case 'ringGroup':
			return 'ring-group';
		case 'account':
			return 'all-company';
		case 'maa':
			return 'welcome-menu';
		case 'phone':
			return 'phone-number';
		case 'fax':
			return 'special-extension';
		case 'intercom':
			return 'special-extension';
		case 'ringer':
			return 'special-extension';
		case 'pager':
			return 'special-extension';
		default:
			throw Error(`The type ${type} doesn't have a mapping into the entity type`);
	}
};

export const getEntityObjectByType = (data, status = 'A', accountId = undefined): any => {
	if (data.type === 'team-member') {
		return {
			data: data.userId,
			status,
			type: 'user',
		};
	} else if (data.type === 'department') {
		return {
			data: data.deptId,
			status,
			type: 'department',
		};
	} else if (data.type === 'welcome-menu') {
		return {
			data: data.id,
			status,
			type: 'maa',
		};
	} else if (data.type === 'all-company') {
		return {
			data: accountId,
			status,
			type: 'account',
		};
	} else if (data.type === 'repeat-menu') {
		return {
			data: 'repeat',
			status,
			type: 'repeat',
		};
	} else if (data.type === 'phone-number') {
		return {
			data: data.number,
			status,
			type: 'phone',
		};
	} else if (data.type === 'special-extension') {
		return {
			data: data.id,
			status,
			type: data.businessClassName.toLowerCase(),
		};
	} else if (data.type === 'ring-group') {
		return {
			data: data.id,
			status,
			type: 'ringGroup',
		};
	} else if (data.type === 'directory') {
		return {
			data: '',
			status,
			type: 'directory',
		};
	} else if (data.type === 'call-queue') {
		return {
			data: data.id,
			status,
			type: 'callQueue',
		};
	}
};

export const isDescendantNode = (parent, child) => {
	let node = child.parentNode;
	while (node) {
		if (node === parent) {
			return true;
		}
		node = node.parentNode;
	}

	return false;
};

export const formatBytes = (bytes, decimals = 0): string => {
	if (bytes === 0) return '0 Bytes';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const getAvatarUrlFromData = (data: any, avatarSize: string = 'default'): string => {
	const regex = new RegExp(/\.(jpeg|jpg|png)$/);

	try {
		const { userInfo } = data;
		let avatars = userInfo ? userInfo.avatars : data.avatars;

		if (avatars.length) {
			// remove messenger generated icons
			avatars = avatars.filter(avatar => regex.test(avatar.url));

			if (avatars.length) {
				// get requested size with fallback to original
				return (avatars.find(avatar => avatar.size === avatarSize) || avatars[0]).url;
			}
			return '';
		}
	} catch (e) {
		return '';
	}

	return '';
};

export const leadZero = (value: number): string => {
	return value !== 0 && String(value).length === 1 ? `0${value}` : String(value);
};

export const formatTimeString = (time: string): string => {
	if (time.length < 4 && time.indexOf(':') < 0) return undefined;

	time = time.replace(/\s/g, ''); //remove all spaces
	const middleIndex = time.indexOf(':');
	const hour = time.substr(0, middleIndex); //up until the colon is the hour
	const minutes = time.substr(middleIndex + 1, 2); //the next 2 will be the minutes
	const amPm = time.substr(middleIndex + 3); //the rest of the string

	return `${hour}:${minutes} ${amPm}`;
};

export const getHourMinuteFromTimeString = (time: string): string => {
	const formatted = formatTimeString(time);
	const hour = Number(formatted.substr(0, time.indexOf(':')));
	const minute = formatted.substr(time.indexOf(':') + 1, 2);

	return `${hour}:${minute}`;
};

export const getAmPmFromTimeString = (time: string): string => {
	const formatted = formatTimeString(time);
	return formatted.substr(time.indexOf(' ') + 1).toLowerCase();
};

export const getLongestRecordingIndex = (recordings: ICallRecording[]): number => {
	return recordings.reduce((searchedIndex, current, currentIndex): number => {
		return current.duration > recordings[searchedIndex].duration ? currentIndex : searchedIndex;
	}, 0);
};
