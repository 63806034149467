import { ITimezone } from '@app/services/web-apis/timezone/api-timezone.domain';

type SupportedTimezone =
	| 'EST'
	| 'PST'
	| 'MST'
	| 'CST'
	| 'AKST'
	| 'HST'
	| 'JST'
	| 'AST'
	| 'ACT'
	| 'AMT'
	| 'BRT'
	| 'FNT'
	| 'MX_EST'
	| 'MX_CST'
	| 'MX_MST'
	| 'MX_PST'
	| 'ART'
	| 'CA_GMT'
	| 'CA_NST'
	| 'CA_AST'
	| 'CA_EST'
	| 'CA_CST'
	| 'CA_MST'
	| 'CA_PST';

type TimezoneToTimezoneNameMap = {
	[timezone in SupportedTimezone]: ITimezone;
};

export const SUPPORTED_DATETIME_TIMEZONES: TimezoneToTimezoneNameMap = {
	// USA
	EST: { abbreviation: 'EST', format: 'US/Eastern', name: 'Eastern Standard Time' },
	PST: { abbreviation: 'PST', format: 'US/Pacific', name: 'Pacific Standard Time' },
	MST: { abbreviation: 'MST', format: 'US/Mountain', name: 'Mountain Standard Time' },
	CST: { abbreviation: 'CST', format: 'US/Central', name: 'Central Standard Time' },
	AKST: { abbreviation: 'AKST', format: 'US/Alaska', name: 'Alaskan Standard Time' },
	HST: { abbreviation: 'HST', format: 'US/Hawaii', name: 'Hawaiian Standard Time' },
	JST: { abbreviation: 'JST', format: 'Asia/Tokyo', name: 'Japan Standard Time' },
	AST: { abbreviation: 'AST', format: 'America/Puerto_Rico', name: 'Atlantic Standard Time' },

	// Brazil
	ACT: { abbreviation: 'ACT', format: 'America/Rio Branco', name: 'Acre Time' },
	AMT: { abbreviation: 'AMT', format: 'America/Manaus', name: 'Amazon Time' },
	BRT: { abbreviation: 'BRT', format: 'America/Sao Paulo', name: 'Brasília Time' },
	FNT: { abbreviation: 'FNT', format: 'America/Noronha', name: 'Fernando de Noronha Time' },

	// Mexico
	MX_EST: { abbreviation: 'MX_EST', format: 'America/Cancun', name: 'Zona Sureste' },
	MX_CST: { abbreviation: 'MX_CST', format: 'America/Mexico_City', name: 'Zona Centro' },
	MX_MST: { abbreviation: 'MX_MST', format: 'America/Chihuahua', name: 'Zona Pacífico' },
	MX_PST: { abbreviation: 'MX_PST', format: 'America/Tijuana', name: 'Zona Noroeste' },

	// Argentina
	ART: { abbreviation: 'ART', format: 'America/Buenos_Aires', name: 'Zona Horaria de Argentina' },

	// Canada
	CA_GMT: { abbreviation: 'CA_GMT', format: 'Europe/London', name: 'English Canadian' },
	CA_NST: { abbreviation: 'CA_NST', format: 'Canada/Newfoundland', name: 'Newfoundland Time' },
	CA_AST: { abbreviation: 'CA_AST', format: 'Canada/Atlantic', name: 'Atlantic Time' },
	CA_EST: { abbreviation: 'CA_EST', format: 'Canada/Eastern', name: 'Eastern Time' },
	CA_CST: { abbreviation: 'CA_CST', format: 'Canada/Central', name: 'Central Time' },
	CA_MST: { abbreviation: 'CA_MST', format: 'Canada/Mountain', name: 'Mountain Time' },
	CA_PST: { abbreviation: 'CA_PST', format: 'Canada/Pacific', name: 'Pacific Time' },
};

export const DEFAULT_TIMEZONE: ITimezone = {
	abbreviation: 'EST',
	name: 'Eastern Standard Time',
	format: 'US/Eastern',
};

export const SUPPORTED_BACKEND_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
