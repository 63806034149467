export type AvailableFeatures =
	| 'CallerIdRequiredForFax'
	| 'SpogSwitcher'
	| 'UseIdentityServer'
	| 'UseSSOLoginPage'
	| 'USLogo'
	| 'BrazilLogo'
	| 'CalaLogo'
	| 'LanguageSwitcher'
	| 'UILanguageSpanish'
	| 'UILanguagePortuguese'
	| 'TermsAndPolicies'
	| 'PhoneNumbersMenu'
	| 'UsePhoneNumberService'
	| 'CallQueue'
	| 'Porting'
	| 'MohUpload'
	| 'ShowTeamMemberCustomMOHUpload'
	| 'ApiSetup'
	| 'CallHistoryBulk'
	| 'Webrtc3WayCalling'
	| 'EmergencyCallNotification'
	| 'PublicIntegrationUI'
	| 'ShowSuppressed30Items'
	| 'EnableRecordViaPhone30'
	| 'EnableCallHistory30'
	| 'UseBulkDownload'
	| 'UseVoicemailService'
	| 'UploadVMGreetingUser'
	| 'UploadVMGreetingDep'
	| 'IsSamlEnabled'
	| 'WebrtcWarmTransfer'
	| 'WebAppLicenseManagement'
	| 'TwoFactorAuthConfigPage'
	| 'ShowOnlyCallRecordingStatus'
	| 'InternationalCalling'
	| 'CallForwardingSchedule'
	| 'MessengerFromCDN'
	| 'OutboundCallBlocking'
	| 'CallHistoryClickToCall'
	| 'FilteringCallHistoryByTeamMember'
	| 'CallPickUpRestriction'
	| 'ManageDidAsCallerId'
	| 'IsVirtualFaxEnabled'
	| 'TenDLC'
	| 'TenDLCOptOutTab'
	| 'CustomerDelegateManagement'
	| 'CheckPhoneRegistrationForMessenger'
	| 'WMGreetingTextToSpeech'
	| 'IsDeviceManagementEnabled'
	| 'IsBulkOperationsPageEnabled'
	| 'RestrictAnalyticsPageToAdmins'
	| 'SpecialExtensionForRemoteAccess'
	| 'AdvancedWelcomeMenuConfigurationOptions'
	| 'TeamMemberBulkUpload'
	| 'CallerIdPrefixManagement'
	| 'RequestNewPort'
	| 'CallQueueSummarizedMetrics'
	| 'CallQueuesXtoExit'
	| 'AllowForCustomerDeviceOrdering'
	| 'SipTieLine'
	| 'UILanguageFrench'
	| 'BusinessContinuity'
	| 'EditingForAllPhoneNumberTypes'
	| 'MessagingProviderConfigEnabled'
	| 'ShowChargesInCallHistory'
	| 'ShowCallRecordingStatus'
	| 'BurstableEnabled';

export type FeaturesInfo = {
	[feature in AvailableFeatures]?: boolean;
};

export interface IPublicFeatureFlagsResponse {
	Items: Array<{
		Id: number;
		Name: string;
		Description: string | null;
		Flag: boolean;
	}>;
	Page: number;
	TotalItems: number;
}

export interface IPublicFeatureFlags {
	UseIdentityServer: boolean;
	UseSSOLoginPage: boolean;
}

export const DEFAULT_COUNTRY_FEATURE_FLAGS: FeaturesInfo = {
	USLogo: true,
	BrazilLogo: false,
	CalaLogo: false,
	LanguageSwitcher: false,
	TermsAndPolicies: true,
};

export const DEFAULT_FEATURE_FLAGS: FeaturesInfo = {
	TenDLC: false,
	CallQueue: false,
	USLogo: true,
	BrazilLogo: false,
	CalaLogo: false,
	LanguageSwitcher: false,
	TermsAndPolicies: true,
	Porting: false,
	MohUpload: false,
	ApiSetup: false,
	CallHistoryBulk: false,
	Webrtc3WayCalling: false,
	MessengerFromCDN: false,
	ShowOnlyCallRecordingStatus: false,
	SipTieLine: false,
	SpogSwitcher: false,
	BusinessContinuity: false,
};

export const DEFAULT_PUBLIC_FEATURE_FLAGS: IPublicFeatureFlags = {
	UseIdentityServer: false,
	UseSSOLoginPage: false,
};
