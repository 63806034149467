import { first, flatMap, map, mergeMap, share, shareReplay, startWith, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';

import {
	DEFAULT_COUNTRY_FEATURE_FLAGS,
	DEFAULT_FEATURE_FLAGS,
	FeaturesInfo,
} from '@app/services/feature-flags/features.domain';

import { ApiService } from '@app/services/api';
import { CacheUtil } from '@app/utils/cache/cache.util';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { CommonService } from '@app/services/Common.service';

export type a = { [key: string]: boolean };
@Injectable({
	providedIn: 'root',
})
export class FeatureFlagsService {
	private url: string = '/accounts/{accountId}/features';

	private cacheKey: string = 'allFeatures';
	private cacheTime: number = 1200;
	private cacheUtil: CacheUtil<FeaturesInfo> = new CacheUtil<FeaturesInfo>(this.cacheTime);
	private cacheResponseUtil: CacheUtil<Observable<FeaturesInfo>> = new CacheUtil<Observable<FeaturesInfo>>(
		this.cacheTime,
	);

	private dataStore: object = {
		companyDirectory: undefined,
		apiIntegration: undefined,
		termsAndPolicies: undefined,
	};

	constructor(private apiService: ApiService, private commonService: CommonService) {}

	callerIdRequiredForFax = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map(flags => flags.CallerIdRequiredForFax));
	};

	spogSwitcher = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map(flags => flags.SpogSwitcher));
	};

	apiIntegration = (): Observable<boolean> => {
		return this.getAppFeature('apiIntegration', 'ApiSettingsEnabled');
	};

	apiSetup = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.PublicIntegrationUI));
	};

	termsAndPolicies = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.TermsAndPolicies));
	};

	phoneNumbersMenuEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.PhoneNumbersMenu));
	};

	usePhoneNumberService = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.UsePhoneNumberService));
	};

	porting = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.Porting));
	};

	callQueue = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.CallQueue));
	};

	languageSwitcher = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.LanguageSwitcher));
	};

	unitedStatesLogo = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.USLogo));
	};

	brazilLogo = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.BrazilLogo));
	};

	calaLogo = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.CalaLogo));
	};

	greetingTextToSpeech = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.WMGreetingTextToSpeech));
	};

	mohUpload = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.MohUpload));
	};

	showTeamMemberCustomMOHUpload = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.ShowTeamMemberCustomMOHUpload));
	};

	emergencyContacts = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.EmergencyCallNotification));
	};

	callHistoryBulk = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.CallHistoryBulk));
	};

	showSuppressed30Items = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.ShowSuppressed30Items));
	};

	enableRecordViaPhone30 = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.EnableRecordViaPhone30));
	};

	enableCallHistory30 = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.EnableCallHistory30));
	};

	useBulkDownload = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.UseBulkDownload));
	};

	useVoicemailService = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.UseVoicemailService));
	};

	uploadVMGreetingUser = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.UploadVMGreetingUser));
	};

	uploadVMGreetingDep = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.UploadVMGreetingDep));
	};

	saml = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.IsSamlEnabled));
	};

	can3wayCall = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.Webrtc3WayCalling));
	};

	isWarmTransferEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.WebrtcWarmTransfer));
	};

	webAppLicenseManagement = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.WebAppLicenseManagement));
	};

	isEdit2FAEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.TwoFactorAuthConfigPage));
	};

	filteringCallHistoryByTeamMember = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.FilteringCallHistoryByTeamMember));
	};

	messengerFromCDN = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.MessengerFromCDN));
	};

	showOnlyCallRecordingStatus = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.ShowOnlyCallRecordingStatus));
	};

	internationalCalling = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.InternationalCalling));
	};

	callForwardingSchedule = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.CallForwardingSchedule));
	};

	outboundCallBlockingEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.OutboundCallBlocking));
	};

	callHistoryClickToCall = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.CallHistoryClickToCall));
	};

	callPickupEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.CallPickUpRestriction));
	};

	manageDidAsCallerId = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.ManageDidAsCallerId));
	};

	manageCallerIdPrefixForPhoneNumber = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.CallerIdPrefixManagement));
	};

	isTenDlcEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => !!flags.TenDLC));
	};

	shouldCheckPhoneRegistrationForMessenger(): Observable<boolean> {
		return this.getAllCommonFeatureFlags().pipe(
			map((flags: FeaturesInfo) => !!flags.CheckPhoneRegistrationForMessenger),
		);
	}

	isSipTieLineEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => true));
	};

	isVirtualFaxEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.IsVirtualFaxEnabled));
	};

	isDelegationEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.CustomerDelegateManagement));
	};

	isDeviceManagementEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.IsDeviceManagementEnabled));
	};

	isBulkOperationsPageEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.IsBulkOperationsPageEnabled));
	};

	isRemoteVoicemailAccessEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.SpecialExtensionForRemoteAccess));
	};

	isAnalyticsPageEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(
			map((flags: FeaturesInfo) => flags.RestrictAnalyticsPageToAdmins),
			map(flagValue => {
				return this.commonService.isAdmin() ? true : !flagValue;
			}),
		);
	};

	isAdvancedWelcomeMenuConfigurationEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(
			map((flags: FeaturesInfo) => flags.AdvancedWelcomeMenuConfigurationOptions),
		);
	};

	isUserBulkLoadFeatureEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.TeamMemberBulkUpload));
	};

	isRequestNewPortFeatureEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.RequestNewPort));
	};

	isBusinessContinuityEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.BusinessContinuity));
	};

	allowForCustomerDeviceOrdering = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.AllowForCustomerDeviceOrdering));
	};

	isEditingAllPhoneNumberTypesEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.EditingForAllPhoneNumberTypes));
	};

	isMessagingProviderConfigEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.MessagingProviderConfigEnabled));
	};

	isBurstableEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.BurstableEnabled));
	};

	isShowChargesInCallHistoryEnabled = (): Observable<boolean> => {
		return this.getAllCommonFeatureFlags().pipe(map((flags: FeaturesInfo) => flags.ShowChargesInCallHistory));
	};

	get allFeatureFlagsSource$(): Observable<FeaturesInfo> {
		if (!this.cacheResponseUtil.has(this.cacheKey)) {
			this.cacheResponseUtil.set(this.cacheKey, this.fetchFeatureFlags());
		}

		return this.cacheResponseUtil.get(this.cacheKey);
	}

	fetchFeatureFlags() {
		return this.apiService.get(`/features`).pipe(
			shareReplay(1),
			map((res: IRegularApiResponse<any>) => {
				if (!res.data || res.data.Items.length <= 0) {
					return DEFAULT_FEATURE_FLAGS;
				}

				return res.data.Items.reduce((acc: FeaturesInfo, curr: any) => {
					acc[curr.Name] = curr.Flag;

					return acc;
				}, {});
			}),
			tap((info: FeaturesInfo) => {
				if (info !== DEFAULT_FEATURE_FLAGS) {
					this.cacheUtil.set(this.cacheKey, info);
				}
			}),
		);
	}

	get publicFeatureFlagsSource$(): Observable<FeaturesInfo> {
		return this.apiService.get(`/features/public`).pipe(
			map((res: IRegularApiResponse<any>) => {
				if (!res.data || res.data.Items.length <= 0) {
					return DEFAULT_FEATURE_FLAGS;
				}

				return res.data.Items.reduce((acc: FeaturesInfo, curr: any) => {
					acc[curr.Name] = curr.Flag;

					return acc;
				}, {});
			}),
			tap((info: FeaturesInfo) => {
				if (info !== DEFAULT_FEATURE_FLAGS) {
					this.cacheUtil.set('publicFeatures', info);
					localStorage.setItem('public_features', JSON.stringify(info));
				}
			}),
			share(),
			first(),
		);
	}

	getAllCommonFeatureFlags(): Observable<FeaturesInfo> {
		if (this.cacheUtil.has(this.cacheKey)) {
			return of(this.cacheUtil.get(this.cacheKey)).pipe(first());
		} else {
			return this.allFeatureFlagsSource$;
		}
	}

	// This is used to cache feature flags right after login
	initFlags(): Observable<FeaturesInfo> {
		return this.fetchFeatureFlags();
	}

	private getAccountFeature(cacheKey: string, featureName: string): Observable<boolean> {
		const current = this.dataStore[cacheKey];
		if (typeof current !== 'undefined') return of(current);

		return this.apiService.get(this.url, { features: featureName }).map(res => {
			if (res.hasError) return false;
			else {
				const next = res.data[0].active;
				this.dataStore[cacheKey] = next;
				return next;
			}
		});
	}

	private getAppFeature(cacheKey: string, featureName: string): Observable<boolean> {
		const current = this.dataStore[cacheKey];
		if (typeof current !== 'undefined') return of(current);

		return this.apiService
			.get<IRegularApiResponse<any>>('/appconfig', { key: featureName })
			.pipe(
				map(res => {
					const next = !!res.data && res.data.VALUE === 'true';
					this.dataStore[cacheKey] = next;
					return next;
				}),
			);
	}

	private getCountryFeature(cacheKey: string, featureName: string): Observable<boolean> {
		const current = this.dataStore[cacheKey];
		if (typeof current !== 'undefined') return of(current);

		return this.apiService.get('/accounts/{accountId}').pipe(
			flatMap((res: IRegularApiResponse<any>) => {
				const country = res.data.country;

				return this.apiService.get(`/features/country/${country}`).pipe(
					map(res => {
						try {
							const next = res.data.FeatureFlags.filter(flag => flag.Feature === featureName)[0].Flag || false;
							this.dataStore[cacheKey] = next;
							return next;
						} catch (e) {
							return false;
						}
					}),
				);
			}),
		);
	}

	getCountryFeatureFlags(): Observable<FeaturesInfo> {
		return this.apiService.get('/accounts/{accountId}').pipe(
			mergeMap((res: IRegularApiResponse<any>) => {
				const country = res.data.country;
				return this.cacheUtil.has(country)
					? of(this.cacheUtil.get(country))
					: this.getCountryFeatureInfoFromApi(country);
			}),
		);
	}

	private getCountryFeatureInfoFromApi(country: string): Observable<FeaturesInfo> {
		return this.apiService.get(`/features/country/${country}`).pipe(
			startWith(DEFAULT_COUNTRY_FEATURE_FLAGS),
			map(FeatureFlagsService.mapFeatureFlags),
			tap((info: FeaturesInfo) => {
				if (info !== DEFAULT_COUNTRY_FEATURE_FLAGS) {
					this.cacheUtil.set(country, info);
				}
			}),
		);
	}

	private static mapFeatureFlags(res: IRegularApiResponse<any>): any {
		if (!res.data || res.data.FeatureFlags.length <= 0) {
			return DEFAULT_COUNTRY_FEATURE_FLAGS;
		}

		return res.data.FeatureFlags.reduce((acc: FeaturesInfo, curr: any) => {
			acc[curr.Feature] = curr.Flag;

			return acc;
		}, {});
	}
}
