import { OverlayRef } from '@angular/cdk/overlay';
import { Component, HostBinding, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DeleteRecordingPromptComponent } from '@app/pages/call-history/components/delete-recording-prompt/delete-recording-prompt.component';
import { CallHistoryService, PromptService, SnackbarService, JwtService, CommonService } from '@app/services';
import { VOICEMAIL_POPUP_CALL_DATA, VOICEMAIL_POPUP_FUNCS, VOICEMAIL_POPUP_REF } from '@utils/constants/injectortokens';
import { getLongestRecordingIndex } from '@utils/helpers/functions';

import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { Decision } from '@app/shared/classes/PromptRef';

@Component({
	selector: 'app-recording-popup',
	templateUrl: './recording-popup.component.html',
	styleUrls: ['./recording-popup.component.scss'],
})
export class RecordingPopupComponent implements OnInit {
	@HostBinding('class.n2p-dropdown-data') style: boolean = false;
	audioSrc: string;
	isLoading: boolean = false;
	isDeleteAvailable: boolean = true;

	constructor(
		@Inject(VOICEMAIL_POPUP_CALL_DATA) private callData: any,
		@Inject(VOICEMAIL_POPUP_REF) private overlayRef: OverlayRef,
		@Inject(VOICEMAIL_POPUP_FUNCS) private injectedFuncs: any,
		private callHistoryService: CallHistoryService,
		private promptService: PromptService,
		private translate: TranslateService,
		private snackbarService: SnackbarService,
		private cService: CommonService,
		private jwtService: JwtService,
	) {
		const isAdmin = this.cService.isAdmin();
		this.isDeleteAvailable = !this.jwtService.hasPolicy('callrecording.block.delete') || isAdmin;
	}

	statusChanged(status: string): void {
		if (status === 'playing') {
			const ids = this.callData.recordings.map(i => i.id);
			this.callHistoryService.setRecordingsStatus(ids, 'S').subscribe(() => {
				const longestRecordingIndex = getLongestRecordingIndex(this.callData.recordings);
				this.callData.recordings[longestRecordingIndex].status = 'S';
			});
		}
	}

	download(): void {
		this.isLoading = true;
		if (this.audioSrc) {
			const tempInvisibleLink = document.createElement('a');
			tempInvisibleLink.href = this.audioSrc;
			tempInvisibleLink.download = `${this.callData.from.number}_vm.wav`;
			tempInvisibleLink.click();
			this.snackbarService.createSuccess(this.translate.instant('CALLS_TABLE_PAGE.RECORDING_DOWNLOAD_SUCCESS'));
			this.isLoading = false;
		}
	}

	sendToMailbox(): void {
		this.isLoading = true;
		const longestRecordingIndex = getLongestRecordingIndex(this.callData.recordings);
		this.callHistoryService
			.sendBulkDownload({
				fileNaming: 'name+dateTime+from+to+duration+callId',
				filters: [],
				items: [
					{
						id: this.callData.recordings[longestRecordingIndex].id,
						type: 'rec',
					},
				],
			})
			.subscribe(({ hasError }) => {
				if (hasError) {
					this.snackbarService.createDanger(this.translate.instant('CALLS_TABLE_PAGE.RECORDING_DOWNLOAD_IS_NOT_SENT'));
				} else {
					this.snackbarService.createSuccess(this.translate.instant('CALLS_TABLE_PAGE.RECORDING_DOWNLOAD_IS_SENT'));
				}

				this.isLoading = false;
			});
	}

	openDeletePrompt(): void {
		const promptRef = this.promptService.create(
			DeleteRecordingPromptComponent,
			{
				audioSrc: this.audioSrc,
				callData: this.callData,
			},
			{
				successType: 'delete',
				successLabel: this.translate.instant('CALLS_TABLE_PAGE.DELETE_VOICEMAIL_PROMPT.DELETE'),
			},
		);
		promptRef.onDismiss().then(decision => {
			if (decision === Decision.CANCEL) return;
			const ids = this.callData.recordings.map(i => i.id);
			this.callHistoryService.setRecordingsStatus(ids, 'D').subscribe((res: IRegularApiResponse<any>) => {
				if (!res.hasError) {
					this.injectedFuncs.refreshCalls();
				} else {
					this.snackbarService.createDanger(this.translate.instant('CALLS_TABLE_PAGE.FAILED_TO_DELETE'));
				}

				this.overlayRef.dispose();
			});
		});
	}

	ngOnInit(): void {
		this.style = true;
		this.isLoading = true;
		this.callHistoryService
			.getRecordingSrc(this.callData.recordings[getLongestRecordingIndex(this.callData.recordings)].id)
			.subscribe(
				res => {
					this.isLoading = false;
					this.audioSrc = res;
				},
				() => {
					this.isLoading = false;
					this.snackbarService.createDanger(this.translate.instant('GLOBALS.GENERAL_ERROR'));
				},
			);
	}
}
