import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs/Observable';

import { ApiPhoneNumbersService } from '@app/services';
import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@n2p/dialog/dialogRef';
import { getEntityObjectByType } from '@utils/helpers/functions';

export class DeleteEntityModel {
	entityId: any;
	entityName: string;
	entityType: string;
	assignedNumbers: Array<{ lineId?: string }>;
	entity: any;
}

@Component({
	selector: 'n2p-dialog-delete-entity',
	templateUrl: './dialog-delete-entity.component.html',
	styleUrls: ['./dialog-delete-entity.component.scss'],
})
export class DialogDeleteEntityComponent implements OnInit {
	deleting: boolean;
	entityTypeText: string;
	entityModel: DeleteEntityModel;
	individually = false;
	assignedToControl = new FormControl([]);
	assignedToControls = new FormGroup({});
	constructor(
		@Inject(DIALOG_DATA) data: DeleteEntityModel,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		private translate: TranslateService,
		private apiPhoneNumbersService: ApiPhoneNumbersService,
	) {
		this.entityModel = data;
		this.entityTypeText = this.getEntityTypeText(this.entityModel.entityType);
		if (this.entityModel.assignedNumbers && this.entityModel.assignedNumbers.length) {
			this.entityModel.assignedNumbers.forEach(i => this.assignedToControls.addControl(i.lineId, new FormControl([])));
		}
	}

	ngOnInit(): void {}

	getEntityTypeText(type): string {
		// TODO implement the rest of the entities
		switch (type) {
			case 'welcome-menu':
				return this.translate.instant('WELCOMEMENU.WM');
			case 'ring-group':
				return this.translate.instant('DASHBOARD.RINGGROUP');
			case 'department':
				return this.translate.instant('DEPARTMENTS.DEPARTMENT');
			default:
				return '';
		}
	}

	cancel(): void {
		this.dialogRef.dismiss(false);
	}

	delete(): void {
		if (this.entityModel.assignedNumbers) {
			const subscriptions = [];
			this.entityModel.assignedNumbers.forEach(line => {
				let control;
				control = !this.individually
					? this.assignedToControl.value
					: this.assignedToControls.get(line.lineId) && this.assignedToControls.get(line.lineId).value;

				if (control && control.length) {
					const selected = control[0];
					if (selected.type === 'delete') {
						subscriptions.push(this.apiPhoneNumbersService.deletePhoneNumber(line.lineId));
					} else {
						const model = this.buildModel(selected, line);
						subscriptions.push(this.apiPhoneNumbersService.updatePhoneNumber(model, true));
					}
				}
			});

			if (subscriptions.length) {
				this.deleting = true;
				Observable.forkJoin(subscriptions).subscribe(res => {
					this.dialogRef.dismiss(true);
					this.deleting = false;
				});
			} else {
				this.dialogRef.dismiss(true);
			}
		}
	}

	private buildModel = (selected, line): any => {
		const entityObj = getEntityObjectByType(selected);
		const phone = {
			number: line.lineId,
			routeToId: entityObj.data,
			routeType: entityObj.type,
		};

		return { ...phone, routesTo: phone['routeToId'].toString() };
	};
}
