import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddForwardingNumberComponent } from '@n2p/add-forwarding-number/add-forwarding-number.component';
import { DialogService } from '@n2p/dialog/dialog.service';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { IRingGroup } from '@app/services/web-apis/ring-groups/api-ring-groups.domain';
import { ApiRingGroupsService } from '@app/services';

@Component({
	selector: 'n2p-add-forwarding-number-dropdown-item',
	templateUrl: './add-forwarding-number-dropdown-item.component.html',
	styleUrls: ['./add-forwarding-number-dropdown-item.component.scss'],
})
export class AddForwardingNumberDropdownItemComponent implements OnInit {
	@Output() onClick = new EventEmitter();
	@Input() ringGroupId: string;
	@Input() assignedNumbers: string[];

	constructor(private dialogService: DialogService, private apiRingGroupService: ApiRingGroupsService) {}

	async showAddForwardingNumber(): Promise<void> {
		const getAssignedNumbers = async (): Promise<Array<string>> => {
			const res: IRegularApiResponse<IRingGroup> = await this.apiRingGroupService
				.getRingGroup(this.ringGroupId, false)
				.toPromise();
			return res && res?.data?.lines.map(l => l.lineId);
		};

		const dialogRef = this.dialogService.create(
			AddForwardingNumberComponent,
			{
				isRingGroup: true,
				restrictedPhoneNumbers: this.assignedNumbers ? this.assignedNumbers : await getAssignedNumbers(),
			},
			{
				width: 440,
			},
		);

		dialogRef.onDismiss().subscribe(num => {
			this.onClick.emit(num);
		});
	}

	ngOnInit(): void {}
}
