import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import 'rxjs/add/observable/forkJoin';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';

import { DIALOG_DATA } from '@n2p/dialog/dialog.injectors';
import { IPhone } from '@app/Common/interfaces/IPhone';
import {
	ApiDevicesService,
	CommonService,
	DeviceServices,
	DialogService,
	LicensesService,
	PromptService,
	SnackbarService,
} from '@app/services';

import { DevicesDeleteComponent } from './desk-phone/delete-devices.component';
import { DevicesAddEditComponent } from './desk-phone/add-edit-devices.component';
import { PhoneNumberFormatterPipe } from '@app/pipes';
import { Decision } from '@app/shared/classes/PromptRef';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IDevice } from '@app/services/web-apis/devices/api-devices.domain';
import { SipDeviceInformationComponent } from '@app/shared/sip-device-information/sip-device-information.component';

@Component({
	selector: 'devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss'],
	providers: [PhoneNumberFormatterPipe],
})
export class TeamMembersDevicesComponent implements OnInit, AfterViewInit {
	loadingDevices: boolean = true;
	loadingDevicesError: boolean = false;
	departments: any[];
	ringOptions: IDropdownOption[];
	selectedRingOption: number;
	selected: boolean;
	canAddDevice: boolean;
	public hasDevices: boolean = true;
	public hasMultipleDeviceLicense: boolean = false;

	serviceAddress: boolean = false;
	showLoader: boolean = true;
	sipDeviceRings: string = '';
	@Input() isEdit: boolean = false;
	public userDevices: IDevice[] = [];
	phones: Array<IPhone> = [];
	@Input() isOptional: boolean;
	@Input() formGroup: FormGroup;
	subscriber: Subscription;
	@Input() formControlName: string;
	@Input() selectedUserId: string = '';
	@Input() deviceid: any;
	@Input() status: any;
	@Input() user: IUser;
	public isAdmin: boolean;
	private toggle: boolean = false;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		private commonService: CommonService,
		private deviceServices: DeviceServices,
		private fb: FormBuilder,
		private snackbarService: SnackbarService,
		private apiDevices: ApiDevicesService,
		private promptService: PromptService,
		private dialogService: DialogService,
		private translate: TranslateService,
		private licenseService: LicensesService,
	) {
		this.selectedUserId = `${this.data.userId}`;
		this.isAdmin = this.commonService.isAdmin();
		this.licenseService
			.getLicenseByCode('devices.multiple')
			.subscribe(res => (this.hasMultipleDeviceLicense = res.unlimited || res.inUseQuantity < res.quantity));
		this.canAddDevice =
			!Object.prototype.hasOwnProperty.call(data, 'canCreatePhysicalDevices') || data.canCreatePhysicalDevices;
	}

	ngOnInit(): void {
		this.initSipDevice();
		this.getSipDeviceRings();
		this.getWebRtc();
		this.getUserDevices();
	}

	ngAfterViewInit(): void {
		if (this.selectedUserId === undefined) {
			return;
		}
	}

	initSipDevice(): void {
		const ringsStr = this.translate.instant('DEVICES_PAGE2.RINGS');
		this.ringOptions = [
			{
				title: `1 ${this.translate.instant('DEVICES_PAGE2.RING')}`,
				value: 1,
			},
		];
		for (let i = 2; i <= 10; i++) {
			this.ringOptions.push({ title: `${i} ${ringsStr}`, value: i });
		}
	}

	toggleThing(e: any): void {
		this.toggle = !this.toggle;
		if (this.toggle) {
			this.setWebRTC('A');
		} else {
			this.setWebRTC('I');
		}
	}

	setWebRTC(status: string): void {
		this.deviceServices.putWebRtc(this.deviceid, status, this.selectedUserId).subscribe(
			result => {
				if (result.hasError || result.errorMessages.length) {
					let errors = result.errorMessages.join(' ');
					this.snackbarService.create({
						status: 'danger',
						text: errors,
						connectTo: null,
					});
				} else if (result.data && result.data.status && `${this.selectedUserId}` === this.commonService.userId) {
					this.commonService.webRtcEnabled = result.data.status === 'A';
					// for dialer mount or unmount
					this.commonService.toggleDialerSub.next();
				}
			},
			(error: any) => {
				let errors = error.errorMessages.join(' ');
				this.snackbarService.create({
					status: 'danger',
					text: errors,
					connectTo: null,
				});
			},
		);
	}

	getWebRtc(): void {
		this.deviceServices.getWebRtc(this.selectedUserId).subscribe(result => {
			if (!result.hasError) {
				this.status = result.data.status;
				this.deviceid = result.data.deviceId;

				this.toggle = false;
				if (this.status == 'A') {
					this.toggle = true;
				}
			}
			this.showLoader = false;
		});
	}

	getSipDeviceRings = () => {
		this.deviceServices.getSipDeviceRings(this.selectedUserId).subscribe(result => {
			if (!result.hasError) {
				this.selectedRingOption = result.data.sipDeviceRings || this.ringOptions[0].value;
			}
		});
	};

	getUserDevices(): void {
		this.loadingDevices = true;
		this.loadingDevicesError = false;
		this.hasDevices = false;

		this.apiDevices.getUserDevices(this.selectedUserId).subscribe(
			result => {
				this.loadingDevices = false;
				if (!result.hasError) {
					this.userDevices = result.data;

					if (this.userDevices != null && this.userDevices !== undefined && this.userDevices.length > 0) {
						this.hasDevices = true;
					}
				}
			},
			error => {
				this.loadingDevices = false;
				this.loadingDevicesError = true;
			},
		);
	}

	getUserDeviceName(device: IDevice): string {
		if (device.deviceType.manufacturer === 'unsupported') {
			return this.translate.instant('DEVICES_PAGE2.UNSUPPORTED_DEVICE');
		}
		return device.deviceType.name;
	}

	showAddDevice(): void {
		const dialogRef = this.dialogService.create(
			DevicesAddEditComponent,
			{ userId: this.selectedUserId, removeOldDevice: !this.hasMultipleDeviceLicense },
			{
				width: 510,
			},
		);

		dialogRef.onDismiss().subscribe(device => {
			if (device) {
				this.modifiedDevice(device);
			}
		});
	}

	showEditDevice(device): void {
		const dialogRef = this.dialogService.create(
			DevicesAddEditComponent,
			{ userId: this.selectedUserId, device: device },
			{
				width: 510,
			},
		);

		dialogRef.onDismiss().subscribe(device => {
			if (device) {
				this.modifiedDevice(device);
			}
		});
	}

	showDeleteDevice(device): void {
		const dialogRef = this.promptService.create(DevicesDeleteComponent, device, {
			width: 510,
			successLabel: this.translate.instant('DEVICES_PAGE2.DELETE'),
			successType: 'delete',
		});

		dialogRef.onDismiss().then(decision => {
			if (decision === Decision.CANCEL) return;
			this.apiDevices
				.removeUserDevice(this.selectedUserId, device.macId == null ? 'unsupported' : device.macId)
				.subscribe(
					result => {
						if (result.hasError || result.errorMessages.length) {
							const errors = result.errorMessages.join(' ');
							this.snackbarService.create({
								status: 'danger',
								text: errors,
								connectTo: undefined,
							});
						} else {
							this.snackbarService.create({
								status: 'success',
								text: this.translate.instant('DEVICES_PAGE.ADD_DEVICE.SUCCESS_DELETE'),
							});
							this.deleteDevice(device);
						}
					},
					(error: any) => {
						const errors = error.errorMessages.join(' ');
						this.snackbarService.create({
							status: 'danger',
							text: errors,
							connectTo: undefined,
						});
					},
				);
		});
	}

	deleteDevice(device): void {
		if (this.hasMultipleDeviceLicense) {
			this.getUserDevices();
			return;
		}

		const index: number = this.userDevices.indexOf(device);
		if (index !== -1) {
			this.userDevices.splice(index, 1);
		}

		this.hasDevices = false;
	}

	modifiedDevice(result: any): void {
		if (result != null) {
			if (this.hasMultipleDeviceLicense) {
				this.getUserDevices();
				return;
			}

			this.userDevices = result;

			if (this.userDevices != null && this.userDevices != undefined && this.userDevices.length > 0) {
				this.hasDevices = true;
			}
		}
	}

	onRingSelect(event: CustomEvent<IDropdownOption>): void {
		const option = event.detail;
		this.deviceServices.putSipDeviceRings(option.value.toString(), this.selectedUserId).subscribe(() => {
			this.selectedRingOption = <number>option.value;
		});
	}

	openSipDeviceInfo(): void {
		this.dialogService.create(
			SipDeviceInformationComponent,
			{
				user: this.user,
				name: `${this.user.firstName} ${this.user.lastName}`,
				extension: this.user.extension,
			},
			{
				width: 580,
			},
		);
	}
}
