import { AvatarComponentType } from './avatar.domain';

export const avatarTypeMap: Record<
	AvatarComponentType,
	{ primary: AvatarComponentType; secondary?: AvatarComponentType }
> = {
	user: {
		primary: 'user',
	},
	'dep-user': {
		primary: 'user',
		secondary: 'department',
	},
	'menu-user': {
		primary: 'user',
		secondary: 'welcome-menu',
	},
	'rg-user': {
		primary: 'user',
		secondary: 'ring-group',
	},
	'special-extension': {
		primary: 'special-extension',
	},
	'ring-group': {
		primary: 'ring-group',
	},
	'welcome-menu': {
		primary: 'welcome-menu',
	},
	department: {
		primary: 'department',
	},
	'queue': {
		primary: 'queue',
	},
};
