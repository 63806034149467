import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';

import { DialogService as N2PDialogService } from '@n2p';
import { EditTeamMemberComponent } from './edit-team-member/edit-team-member.component';
import {
	ApiAccountsService,
	ApiUsersService,
	CommonService,
	DialogService,
	FeatureFlagsService,
	PromptService,
	SnackbarService,
	AuthStorageData,
} from '@app/services';
import { DepartmentService } from '@app/services/department/department.service';
import { DeleteTeamMemberComponent } from './DeleteTeamMember/delete-team-member.component';
import { AddTeamMemberComponent } from '@app/pages/teammembers/add-team-member/add-team-member.component';
import { LocalSearchService } from '@app/services/local-search/local-search.service';
import { IDepartment } from '@app/services/web-apis/departments/api-departments.domain';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { Decision } from '@app/shared/classes/PromptRef';
import {
	GA_TEAM_MEMBERS_ADD,
	GA_TEAM_MEMBERS_DELETE,
	GA_TEAM_MEMBERS_EDIT,
	GoogleAnalyticsService,
} from '@app/services/google-analytics';
import { IAccount } from '@app/services/web-apis/accounts/api-accounts.domain';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { finalize, take } from 'rxjs/operators';
import { IUsersCustomMoHMap, IUsersCustomMohMapItem } from './teammembers.domain';
import { ApiMusicOptionsService } from '@app/services/web-apis/music-options/api-music-options.service';
import { MusicAudioType } from '@app/services/web-apis/music-options/api-music-options.constant';
import { CHECK_SRC_REGEX } from '@app/Common';

declare var $: any;

@Component({
	selector: 'app-teammembers',
	templateUrl: './teammembers.component.html',
	moduleId: module.id,
	styleUrls: ['./teammembers.component.scss'],
})
export class TeamMembersComponent implements OnInit, OnDestroy {
	MAX_USERS_PER_PAGE: number = 50;
	usersData: Array<IUser> = [];
	filteredUsers: Array<IUser> = [];
	paginatedUsers: Array<IUser> = [];
	usersCustomMoHMap: IUsersCustomMoHMap = {};
	departments: Array<IDepartment> = [];
	loggedInUserId: string;
	searchFieldText: string = '';
	isAdmin: boolean;
	dataLoadedUsers: boolean = false;
	counterData: Array<any> = [];
	isCallServerV3User: boolean;
	isBrazilianAccount: boolean = false;
	accountInfo: IAccount;
	subscriptions: Subscription = new Subscription();
	isUserBulkLoadFeatureEnabled: boolean = false;
	isCustomMOHColumnShown: boolean = false;
	playingCustomMoHUserId: number | null = null;

	private _maxUsers: number = 0;

	constructor(
		public tenantService: ApiTenantService,
		private dialogService: DialogService,
		private commonService: CommonService,
		private deptService: DepartmentService,
		private promptService: PromptService,
		private snackbarService: SnackbarService,
		private accountsAPI: ApiAccountsService,
		private apiUsers: ApiUsersService,
		private translate: TranslateService,
		private n2pDialogService: N2PDialogService,
		private featureFlags: FeatureFlagsService,
		private localSearchService: LocalSearchService,
		private gtag: GoogleAnalyticsService,
		private apiMusicOptionsService: ApiMusicOptionsService,
		private authStorageDataService: AuthStorageData,
	) {}

	get usersLeftOnAccount(): number {
		// only return count if both responses have loaded
		const count = this._maxUsers - this.usersData.length;

		return count < 0 || count === this._maxUsers ? 0 : count;
	}

	get canAddUsers(): boolean {
		return this._maxUsers - this.usersData.length > 0;
	}

	get totalCount(): number {
		return this.filteredUsers ? this.filteredUsers.length : 0;
	}

	get columnsCount(): number {
		if (this.isCustomMOHColumnShown) {
			return 8;
		}
		return 7;
	}

	ngOnInit(): void {
		this.isCallServerV3User = this.authStorageDataService.isV3CallServer;
		this.loggedInUserId = this.commonService.userId;
		this.isAdmin = this.commonService.isAdmin();

		this.getAllUsersByAccount();
		forkJoin([
			this.featureFlags.isUserBulkLoadFeatureEnabled(),
			this.featureFlags.showTeamMemberCustomMOHUpload(),
		]).subscribe(res => {
			this.isUserBulkLoadFeatureEnabled = res[0];
			this.isCustomMOHColumnShown = res[1];
		});

		// update logged in user info in the table
		// if edit was initiated from sidebar
		this.apiUsers.user$.subscribe(user => {
			if (user.userId === +this.loggedInUserId) {
				const ind = this.usersData.findIndex(u => u.userId === user.userId);
				if (ind !== -1) {
					this.usersData[ind] = user;
					this.updateShownData();
				}
			}
		});

		this.getAccountInfo();

		if (this.isAdmin) {
			this.getAllDepartments();
			this.fetchMaxAllowedUsers();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}

	fetchMaxAllowedUsers(): void {
		this.accountsAPI.getAccount().subscribe(result => {
			if (!result.hasError) {
				this._maxUsers = result.data.maxUsers;
			}
		});
	}

	getAccountInfo(): void {
		this.subscriptions.add(
			this.accountsAPI.account$.subscribe(value => {
				this.accountInfo = value;
				this.isBrazilianAccount = this.accountInfo.country === 'BR';
			}),
		);
	}

	getAllDepartments(): void {
		this.deptService.getAccountDepartments().subscribe(result => {
			if (!result.hasError) {
				this.departments = result.data;
			}
		});
	}

	getAllUsersByAccount(): void {
		this.dataLoadedUsers = false;

		this.apiUsers.getAllUsers(false).subscribe(
			result => {
				if (!result.hasError) {
					// add key to userData
					this.usersData = result.data.map(
						(user): IUser => {
							return {
								...user,
								deptNames: this.setDeptNames(user),
								compDir: !user.compDir
									? {
											enabled: false,
											audioType: 1,
									  }
									: user.compDir,
							};
						},
					);

					this.filteredUsers = this.usersData;
					this.usersCustomMoHMap = this.usersData.reduce((acc: IUsersCustomMoHMap, user) => {
						acc[user.userId] = {
							url: null,
							isLoading: false,
						};
						return acc;
					}, {});
					this.setPaginatedUsers(1);
					this.dataLoadedUsers = true;
				}
			},
			error => {
				this.dataLoadedUsers = true;
			},
		);
	}

	setDeptNames(user: any): string | null {
		if (!user.members) return null;

		return user.members.reduce((acc: string, member): string => {
			if (member.name.trim().length === 0) return acc;

			return acc.length === 0 ? member.name : `${acc}, ${member.name}`;
		}, '');
	}

	showEditUser(tm: any): void {
		const dialogRef = this.n2pDialogService.create(
			EditTeamMemberComponent,
			{ ...tm, canCreateDidlessUsers: this.accountInfo.canCreateDidlessUsers },
			{
				width: 800,
			},
		);
		dialogRef
			.onDismiss()
			.pipe(take(1))
			.subscribe((user: IUser) => {
				if (user) {
					if (user.status === 'D') {
						this.deleteUser(user);
					} else {
						const ind = this.usersData.findIndex(u => u.userId === user.userId);
						this.usersData[ind] = user;
						this.updateShownData();
						this.gtag.event(GA_TEAM_MEMBERS_EDIT);
					}
				}
			});
	}

	showCreateUser(): void {
		if (!this.canAddUsers) return;
		const dialogRef = this.dialogService.create(
			AddTeamMemberComponent,
			{
				canCreateDidlessUsers: this.accountInfo.canCreateDidlessUsers,
			},
			{
				width: 559,
			},
		);
		dialogRef.onDismiss().subscribe(usr => {
			if (usr) {
				this.usersData = this.usersData
					.concat([usr])
					.sort((a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`));
				this.updateShownData();
				this.snackbarService.create({
					status: 'success',
					text: this.translate.instant('ADD_TEAM_MEMBER_PAGE.USER_SUCCESSFULLY_ADDED'),
					connectTo: undefined,
				});
				this.gtag.event(GA_TEAM_MEMBERS_ADD);
			}
		});
	}

	showDeleteUser(user: any): void {
		const dialogRef = this.promptService.create(DeleteTeamMemberComponent, user, {
			width: 510,
			successLabel: this.translate.instant('DELETE_TEAM_MEMBER_PAGE.DELETE'),
			successType: 'delete',
		});

		dialogRef.onDismiss().then(decision => {
			if (decision === Decision.CANCEL) return;
			this.deleteUser(user);
		});
	}

	filter(searchText: string): void {
		this.searchFieldText = searchText;

		this.setFilteredUsers(searchText);
		this.setPaginatedUsers(1);
	}

	setFilteredUsers(searchText: string): void {
		const fieldsToMatch = ['status', 'extension', 'email', 'deptNames', 'fullName'];

		this.filteredUsers = this.localSearchService.search(this.usersData, searchText, fieldsToMatch);
	}

	setPaginatedUsers(pageNumber: number): void {
		const startingIndex = (pageNumber - 1) * this.MAX_USERS_PER_PAGE;

		this.paginatedUsers = this.filteredUsers.slice(startingIndex, startingIndex + this.MAX_USERS_PER_PAGE);

		this.setCounterData();
	}

	setCounterData(): void {
		this.counterData[0] = { key: this.translate.instant('TEAMMEMBERS_PAGE.TOTAL'), value: this.totalCount };
	}

	updateDepartments(dept: IDepartment): void {
		this.departments = this.departments.map(d => {
			return d.deptId === dept.deptId ? dept : d;
		});
	}

	updateShownData(): void {
		if (this.searchFieldText) {
			this.setFilteredUsers(this.searchFieldText);
		} else {
			this.filteredUsers = this.usersData;
		}

		this.setPaginatedUsers(1);
	}

	private deleteUser(user: any): void {
		const { userId } = user;
		this.dataLoadedUsers = false;
		this.apiUsers.removeUser(userId).subscribe(
			result => {
				if (result.hasError || result.errorMessages.length) {
					const errors = result.errorMessages.map(m => m.message).join(' ');
					this.snackbarService.create({
						status: 'danger',
						text: errors,
						connectTo: undefined,
					});
				} else {
					this.usersData = this.usersData
						.filter(u => u.userId !== userId)
						.sort((a, b) => `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`));
					this.updateShownData();
					this.snackbarService.create({
						status: 'success',
						text: `${user.firstName} ${user.lastName} ${this.translate.instant(
							'GLOBALS.DELETED_SUCCESSFULLY_MESSAGE',
						)}`,
						connectTo: undefined,
					});
					this.gtag.event(GA_TEAM_MEMBERS_DELETE);
				}
				this.dataLoadedUsers = true;
			},
			(error: any) => {
				const errors = error.errorMessages.join(' ');
				this.snackbarService.create({
					status: 'danger',
					text: errors,
					connectTo: undefined,
				});
				this.dataLoadedUsers = true;
			},
		);
	}

	loadCustomMoH(userId: number): void {
		if (!this.usersCustomMoHMap[userId].isLoading) {
			this.usersCustomMoHMap[userId].isLoading = true;

			this.apiMusicOptionsService
				.getUserAudioFile(userId, MusicAudioType.MOH)
				.pipe(
					finalize(() => {
						this.usersCustomMoHMap[userId].isLoading = false;
					}),
				)
				.subscribe(result => {
					const { audioContent } = result.data;
					const src: string =
						audioContent && CHECK_SRC_REGEX.test(audioContent) ? audioContent : `data:audio/wav;base64,${audioContent}`;
					this.usersCustomMoHMap[userId].url = src;
					// we need setTimeout to wait for the audio player to be rendered
					setTimeout(() => (this.playingCustomMoHUserId = userId), 200);
				});
		}
	}

	playCustomMoH(userId: number): void {
		this.playingCustomMoHUserId = userId;
	}

	stopCustomMoH(): void {
		this.playingCustomMoHUserId = null;
	}

	getMoHData(user: IUser): IUsersCustomMohMapItem {
		return this.usersCustomMoHMap[user.userId];
	}

	getCustomMoHPlayerClassName(userId: number): string {
		return `custom-moh-player${this.usersCustomMoHMap[userId].url === null ? ' custom-moh-player--hidden' : ''}`;
	}
}
