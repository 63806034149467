import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { ClaimType, DEFAULT_ROUTE, DID_LESS_COUNTRIES, FeatureType, Policies } from '@app/Common/constants';
import { AuthGuard } from '@app/guards/auth/auth.guard';
import { ClaimGuard } from '@app/guards/claim/claim.guard';
import { CountryGuard } from '@app/guards/country/country.guard';
import { FeatureGuard } from '@app/guards/feature/feature.guard';
import { PolicyGuard } from '@app/guards/policy/policy.guard';
import { RoleGuard } from '@app/guards/role/role.guard';
import { AppTypeGuard } from '@app/guards/app-type/app-type.guard';
import { LicenseWithPrivateGuard } from '@app/guards/license-with-private/license-with-private.guard';
import { ApiCallBlockingService } from '@app/services/web-apis/call-blocking/api-call-blocking.service';
import { AppContainerComponent } from '@app/pages/app-container/app-container.component';
import { CompanyComponent } from '@app/pages/company/company.component';
import { DepartmentsComponent } from '@app/pages/departments/departments.component';
import { ExternalComponent } from '@app/n2p-components/external/external.component';
import { NoPermissionsComponent } from '@app/pages/no-permissions/no-permissions.component';
import { PhoneNumbersComponent } from '@app/pages/phone-numbers/phone-numbers.component';
import { TeamMembersComponent } from '@app/pages/teammembers/teammembers.component';
import { WelcomemenuComponent } from '@app/pages/welcomemenu/welcomemenu.component';
import { IUserRole } from '@app/services/web-apis/users/api-users.domain';

const routes: Routes = [
	// These routes are public
	{
		path: 'sso',
		loadChildren: () => import('app/pages/sso/sso.module').then(m => m.SsoModule),
	},
	{
		path: 'forgotpassword',
		loadChildren: () => import('app/pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
	},
	{
		path: 'impersonation-error',
		loadChildren: () =>
			import('app/pages/impersonation-error/impersonation-error.module').then(m => m.ImpersonationErrorModule),
	},
	// This is where the default authenticated route is set
	{
		path: '',
		pathMatch: 'full',
		redirectTo: DEFAULT_ROUTE,
	},
	// These routes are private
	{
		path: '',
		component: AppContainerComponent,
		canActivate: [AuthGuard],
		children: [
			// redirects
			{
				path: 'company',
				pathMatch: 'full',
				redirectTo: '/company/teammembers',
				canActivate: [AppTypeGuard],
				data: {
					app: ['classic', 'uam', 'admin', 'sipTrunking'],
					customRedirects: {
						sipTrunking: '/company/delegation',
					},
				},
			},
			{
				path: 'settings',
				pathMatch: 'full',
				redirectTo: '/settings/schedules',
			},
			{
				path: 'receptionist-console',
				pathMatch: 'full',
				canLoad: [AuthGuard, PolicyGuard, AppTypeGuard],
				canActivate: [AppTypeGuard],
				loadChildren: () =>
					import('app/pages/receptionist-console/receptionist-console.module').then(m => m.ReceptionistConsoleModule),
				data: {
					hideMainNav: true,
					redirectUrl: 'no-permissions',
					policy: Policies.RCON,
					app: ['classic', 'uam'],
				},
			},
			{
				path: 'no-permissions',
				pathMatch: 'full',
				component: NoPermissionsComponent,
			},

			// lazy loaded modules
			{
				path: 'analytics',
				loadChildren: () => import('app/pages/analytics/analytics.module').then(m => m.AnalyticsModule),
				canLoad: [AuthGuard, FeatureGuard, AppTypeGuard],
				data: {
					featureType: FeatureType.ANALYTICS_PAGE_ENABLED,
					redirectUrl: '/dashboard',
					app: ['classic', 'uam', 'admin'],
				},
			},
			{
				path: 'calls',
				loadChildren: () => import('app/pages/call-history/call-history.module').then(m => m.CallHistoryModule),
				canLoad: [AuthGuard, FeatureGuard, AppTypeGuard],
				data: {
					featureType: FeatureType.ENABLE_CALL_HISTORY_30,
					app: ['classic', 'uam', 'admin'],
				},
			},
			{
				path: 'dashboard',
				loadChildren: () => import('app/pages/dashboard/dashboard.module').then(m => m.DashboardModule),
				canLoad: [AuthGuard, AppTypeGuard],
				data: {
					app: ['classic', 'uam'],
				},
			},
			{
				path: 'messages',
				loadChildren: () => import('app/pages/messages/messages.module').then(m => m.MessagesModule),
				canLoad: [AuthGuard, CountryGuard, AppTypeGuard],
				data: {
					restrictedToCountries: DID_LESS_COUNTRIES,
					redirectUrl: '/',
					app: ['classic'],
				},
			},
			{
				path: 'settings',
				loadChildren: () => import('app/pages/settings/settings.module').then(m => m.SettingsModule),
				canLoad: [AuthGuard],
			},

			{
				path: 'call-qs',
				loadChildren: () => import('app/pages/call-qs/call-qs.module').then(m => m.CallQsModule),
				canLoad: [AuthGuard, RoleGuard, AppTypeGuard],
				canActivate: [LicenseWithPrivateGuard],
				data: {
					licenseCode: 'customer.callqueue',
					requiredRole: IUserRole.ADMIN,
					redirectUrl: 'company',
					app: ['classic', 'uam', 'admin'],
				},
			},

			{
				path: 'sip-trunking',
				loadChildren: () => import('@app/pages/sip-trunking/sip-trunking.module').then(m => m.SipTrunkingModule),
				canLoad: [RoleGuard, PolicyGuard],

				data: {
					requiredRole: IUserRole.ADMIN,
					policy: 'siptrunk',
					redirectUrl: '/',
				},
			},
			// company routes
			{
				path: 'company',
				component: CompanyComponent,
				canActivate: [AppTypeGuard],
				data: {
					app: ['classic', 'uam', 'admin', 'sipTrunking'],
				},
				children: [
					{
						path: 'companyprofile',
						data: {
							app: ['classic', 'uam', 'admin'],
						},
						canLoad: [AppTypeGuard],
						loadChildren: () =>
							import('app/pages/company-profile/company-profile.module').then(m => m.CompanyProfileModule),
					},
					{
						path: 'teammembers',
						component: TeamMembersComponent,
						canActivate: [AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
					},
					{
						path: 'departments',
						canActivate: [AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
						component: DepartmentsComponent,
					},
					{
						path: 'ringgroups',
						canActivate: [AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
						loadChildren: () => import('app/pages/ring-groups/ring-groups.module').then(m => m.RingGroupsModule),
					},
					{
						path: 'phonenumbers',
						canActivate: [AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
						component: PhoneNumbersComponent,
					},
					{
						path: FeatureType.PORTS,
						component: ExternalComponent,
						canActivate: [AuthGuard, RoleGuard, FeatureGuard],
						data: {
							externalConfig: {
								bundleName: 'porting',
							},
							requiredRole: IUserRole.ADMIN,
							featureType: FeatureType.PORTS,
						},
					},
					{
						path: 'welcomemenus',
						canActivate: [AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
						component: WelcomemenuComponent,
					},
					{
						path: 'devices',
						component: ExternalComponent,
						canActivate: [AuthGuard, RoleGuard, AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
							requiredRole: IUserRole.ADMIN,
							redirectUrl: 'company',
							externalConfig: {
								bundleName: 'devices',
							},
						},
					},
					{
						path: 'specialextensions',
						loadChildren: () =>
							import('app/pages/special-extensions/special-extensions.module').then(m => m.SpecialExtensionsModule),
						canLoad: [AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
					},
					{
						path: 'directory',
						loadChildren: () => import('app/pages/directories/directories.module').then(m => m.DirectoriesModule),
						canLoad: [AuthGuard, RoleGuard, AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
							requiredRole: IUserRole.ADMIN,
							redirectUrl: 'company',
						},
					},
					{
						path: 'call-blocking',
						loadChildren: () => import('app/pages/call-blocking/call-blocking.module').then(m => m.CallBlockingModule),
						canLoad: [AuthGuard, AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
						},
						resolve: {
							initData: ApiCallBlockingService,
						},
					},
					{
						path: 'outbound-call-blocking',
						loadChildren: () =>
							import('app/pages/outbound-call-blocking/outbound-call-blocking.module').then(
								m => m.OutboundCallBlockingModule,
							),
						canLoad: [AuthGuard, FeatureGuard, RoleGuard, AppTypeGuard],
						resolve: {
							initData: ApiCallBlockingService,
						},
						data: {
							app: ['classic', 'uam', 'admin'],
							requiredRole: IUserRole.ADMIN,
							redirectUrl: 'company',
							featureType: FeatureType.OUTBOUND_CALL_BLOCKING,
						},
					},
					{
						path: 'virtual-fax',
						loadChildren: () => import('app/pages/virtual-fax/virtual-fax.module').then(m => m.VirtualFaxModule),
						canLoad: [FeatureGuard, RoleGuard, AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
							requiredRole: IUserRole.ADMIN,
							redirectUrl: 'company',
							featureType: FeatureType.VIRTUAL_FAX,
						},
					},
					{
						path: 'delegation',
						loadChildren: () => import('app/pages/delegation/delegation.module').then(m => m.DelegationModule),
						canLoad: [FeatureGuard, RoleGuard, ClaimGuard, AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin', 'sipTrunking'],
							requiredRole: IUserRole.ADMIN,
							redirectUrl: 'company',
							featureType: FeatureType.DELEGATION,
							claimType: ClaimType.DOES_NOT_HAVE_IMPERSONATE_CLAIM,
						},
					},
					{
						path: 'device-management',
						component: ExternalComponent,
						canActivate: [FeatureGuard, AppTypeGuard],
						data: {
							app: ['classic', 'uam', 'admin'],
							externalConfig: {
								bundleName: 'device-management',
							},
							featureType: FeatureType.DEVICE_MANAGEMENT,
						},
					},
				],
			},
			{
				path: 'phone-numbers',
				loadChildren: () =>
					import('@app/routes/phone-numbers-menu/phone-numbers-menu.module').then(m => m.PhoneNumbersMenuModule),
				canLoad: [AuthGuard, FeatureGuard],
				data: {
					featureType: FeatureType.PHONE_NUMBERS_MENU,
				},
			},
		],
	},
	// This redirect for all unknown routes
	{ path: '**', redirectTo: DEFAULT_ROUTE },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadAllModules,
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
