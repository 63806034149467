import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

import { ApiService } from '@app/services/api/api.service';
import { IDevice, ISipDeviceInfoResponse } from '@app/services/web-apis/devices/api-devices.domain';
import { IRegularApiResponse } from '@app/services/web-apis/common-api.domain';
import { getApiV2Url } from '@app/Common';

@Injectable()
export class ApiDevicesService {
	constructor(private apiService: ApiService) {}

	getDeviceTypes(category?: string): Observable<any> {
		return this.apiService.get(`/devicetypes${category ? `?category=${category}` : ''}`);
	}

	getUserDevices(userId: number | string): Observable<IRegularApiResponse<Array<IDevice>>> {
		return this.apiService.get(`/accounts/{accountId}/users/${userId}/devices`);
	}

	getAccountDevices(): Observable<IRegularApiResponse<Array<IDevice>>> {
		return this.apiService.get('/accounts/{accountId}/devices');
	}

	createUserDevice(
		userId: number | string,
		macId: string,
		id: number | string,
		manufacturer: string,
		serialNumber: number,
		removeOldDevice: boolean = true,
	): Observable<any> {
		const body = {
			deviceType: { id, manufacturer },
			macId,
			serialNumber,
		};

		return this.apiService.post(
			`/accounts/{accountId}/users/${userId}/devices?removeOldDevice=${removeOldDevice}`,
			body,
		);
	}

	removeUserDevice(userId: number | string, macId: string): Observable<any> {
		return this.apiService.delete(`/accounts/{accountId}/users/${userId}/devices/${macId}`);
	}

	editUserDevice(
		userId: number | string,
		macId: string,
		newMacId: string,
		newDeviceTypeId: number | string,
		manufacturer: string,
		serialNumber: number,
	): Observable<any> {
		const body = {
			deviceType: {
				id: newDeviceTypeId,
				manufacturer,
			},
			macId: newMacId,
			serialNumber,
		};

		return this.apiService.put(`/accounts/{accountId}/users/${userId}/devices/${macId}`, body);
	}

	assignUserDevice(userId: number | string, macId: string): Observable<any> {
		return this.apiService.put(`/accounts/{accountId}/users/${userId}/devices/${macId}/assign`);
	}

	createDevice(
		macId: string,
		deviceTypeId: number | string,
		manufacturer: string,
		serialNumber: number,
	): Observable<any> {
		const body = {
			deviceType: {
				id: deviceTypeId,
				manufacturer,
			},
			macId,
			serialNumber,
		};

		return this.apiService.post('/accounts/{accountId}/devices', body);
	}

	removeDevice(macId: string): Observable<any> {
		return this.apiService.delete(`/accounts/{accountId}/devices/${macId}`);
	}

	assignDevice(macId: string): Observable<any> {
		return this.apiService.put(`/accounts/{accountId}/devices/${macId}/assign`);
	}

	getSipDeviceInfoByExtension(
		extension: number | string,
		limit: number = 10,
		status: 'active' | 'inactive' | '' = 'active',
	): Observable<ISipDeviceInfoResponse> {
		return this.apiService.get(
			`/sip-registrations?extension=${extension}&limit=${limit}&registration_status=${status}`,
			{},
			{
				baseURL: getApiV2Url(),
			},
		);
	}
}
