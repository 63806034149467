import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	IDropdownAction,
	IDropdownOption,
	ISelectedDropdownOption,
} from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { DropdownActionType, InitialsComponentBackground } from 'n2p-ui-library/constants';
import { TranslateService } from '@ngx-translate/core';

import { ApiDepartmentsService, SnackbarService } from '@app/services';
import {
	IDepartment,
	IDepartmentUserLight,
	IUserDepartment,
} from '@app/services/web-apis/departments/api-departments.domain';
import { DIALOG_DATA, DIALOG_REF } from '@utils/constants/injectortokens';
import { IUser } from '@app/services/web-apis/users/api-users.domain';

@Component({
	selector: 'app-users-assigned-departments',
	templateUrl: './users-assigned-departments.component.html',
	styleUrls: ['./users-assigned-departments.component.scss'],
	providers: [
		{
			provide: DIALOG_DATA,
			useValue: null,
		},
		{
			provide: DIALOG_REF,
			useValue: null,
		},
	],
})
export class UsersAssignedDepartmentsComponent implements OnInit {
	@Input() user: IUser;
	@Input('user') set userSetter(value: IUser) {
		this.user = value;
		this.setDepartmentsData();
	}

	private departments: IDepartment[];
	@Input('departments') set departmentsSetter(value: IDepartment[]) {
		this.departments = value;
		this.setDepartmentsData();
	}

	@Output() onDeptUpdate: EventEmitter<IDepartment> = new EventEmitter();

	departmentOptions: IDropdownOption[] = [];
	selectedDepartments: number[];
	departmentActions: IDropdownAction[] = [
		{
			title: this.translateService.instant('TEAMMEMBERS_PAGE.ADD_DEPARTMENT'),
			type: DropdownActionType.PRIMARY,
		},
	];

	showAddDepartmentFlag: boolean = false;

	constructor(
		private apiDepartmentsService: ApiDepartmentsService,
		private translateService: TranslateService,
		private snackbarService: SnackbarService,
	) {}

	ngOnInit(): void {
		this.setDepartmentsData();
	}

	onDepartmentsChange(event: CustomEvent<ISelectedDropdownOption>, isAddAction: boolean): void {
		const dept = this.departments.find(d => event.detail.value === d.deptId);
		const deptUsers = this.getNewMembers(dept, isAddAction);

		this.apiDepartmentsService.updateDepartmentUsers(dept.deptId, deptUsers).subscribe(
			result => {
				if (result.hasError) {
					return this.snackbarService.createDanger(result.errorMessages.map(m => m.message).join(','));
				}

				// user members update
				let newUserMembers: IUserDepartment[];
				if (isAddAction) {
					newUserMembers = this.user.members.concat([
						{ id: dept.deptId, name: dept.name, businessClass: 'US', ownerId: 0, status: null },
					]);
				} else {
					newUserMembers = this.user.members.filter(m => m.id !== dept.deptId);
				}
				this.user.members = newUserMembers;

				this.onDepartmentsChangeSuccess(dept, isAddAction);
				this.updateDepartment(dept, deptUsers);
			},
			error => {
				this.snackbarService.createDanger(error.errorMessages);
			},
		);
	}

	onDepartmentAction(): void {
		this.showAddDepartmentFlag = true;
	}

	onAddDepartment(dept: IDepartment): void {
		const newOption: IDropdownOption = {
			title: dept.name,
			subTitle: dept.extension,
			value: dept.deptId,
			initials: {
				value: dept.name,
				background: InitialsComponentBackground.PRIMARY_DARK_1,
			},
		};

		this.departmentOptions = this.departmentOptions.concat([newOption]);
		this.departmentOptions.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1));

		this.hideAddDeptPopup();
	}

	onOpenDropdown(): void {
		const dropdownOptionsNode: HTMLDivElement = document.querySelector('.n2pc-dropdown-options');

		if (dropdownOptionsNode) {
			dropdownOptionsNode.style.zIndex = '1000';
		}
	}

	hideAddDeptPopup(): void {
		this.showAddDepartmentFlag = false;
	}

	private getNewMembers(dept: IDepartment, isAddAction: boolean): IDepartmentUserLight[] {
		const userIds: IDepartmentUserLight[] = dept.members.map(m => ({ userId: m.userId }));
		const userId: number = this.user.userId;
		return isAddAction ? userIds.concat([{ userId }]) : userIds.filter(u => u.userId !== this.user.userId);
	}

	private onDepartmentsChangeSuccess(dept: IDepartment, isAddAction: boolean): void {
		const str1 = this.translateService.instant('ADD_TEAM_MEMBER_PAGE.DEPARTMENT');
		const str2 = this.translateService.instant(
			isAddAction ? 'ADD_TEAM_MEMBER_PAGE.HAS_BEEN_ADDED' : 'ADD_TEAM_MEMBER_PAGE.HAS_BEEN_REMOVED',
		);
		const text = `${str1} ${dept.name} ${str2} ${this.userFullName}!`;

		this.snackbarService.createSuccess(text);
	}

	private get userFullName(): string {
		return `${this.user.firstName} ${this.user.lastName}`;
	}

	private updateDepartment(dept: IDepartment, deptUsers: IDepartmentUserLight[]): void {
		this.onDeptUpdate.emit({
			...dept,
			members: dept.members.filter(m => deptUsers.some(du => du.userId === m.userId)),
		});
	}

	private setDepartmentsData(): void {
		if (this.departments && this.departments.length && this.user) {
			this.departmentOptions = this.departments.map(d => {
				return {
					title: d.name,
					subTitle: d.extension,
					value: d.deptId,
					initials: {
						value: d.name,
						background: InitialsComponentBackground.PRIMARY_DARK_1,
					},
				};
			});

			this.selectedDepartments = this.user.members.map(d => d.id);
		}
	}
}
