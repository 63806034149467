import { Component, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { LoaderUtil } from 'n2p-ui-library/utils/loader/loader.util';

import { environment } from '@env/environment';
import { EXTERNAL_APPS_NAMESPACE, EXTERNAL_APPS_VENDORS_CHUNK_NAME, IExternalApp } from '@n2p/external/external.domain';
import { getApiUrl, getApiV2Url, getAuthUrl } from '@app/Common';
import { ApiTimezoneService, AuthStorageData, FeatureFlagsService } from '@app/services';
import { TranslationService } from '@app/translation/translation.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { IUserRole } from '@app/services/web-apis/users/api-users.domain';
import { FeaturesInfo } from '@app/services/feature-flags/features.domain';

@Component({
	selector: 'n2p-external',
	templateUrl: './external.component.html',
})
export class ExternalComponent implements OnInit, OnDestroy {
	@Input() propsBundleName: string = '';
	@Input() additionalData: any;
	@Output() callbackFunction: EventEmitter<void> = new EventEmitter();

	private window: Window = this.document.defaultView;
	private applicationBundleName: string = '';

	constructor(
		private activatedRoute: ActivatedRoute,
		private elementRef: ElementRef,
		private authStorageData: AuthStorageData,
		private translationService: TranslationService,
		private tenantService: ApiTenantService,
		private timezoneService: ApiTimezoneService,
		private featureFlagsService: FeatureFlagsService,
		@Inject(DOCUMENT) private document: Document,
		private router: Router,
	) {}

	get app(): IExternalApp | undefined {
		const namespace = this.window[EXTERNAL_APPS_NAMESPACE];
		return namespace && namespace[this.applicationBundleName] && namespace[this.applicationBundleName].default;
	}

	ngOnInit(): void {
		const { bundleName = this.propsBundleName } = this.activatedRoute.snapshot.data.externalConfig || {};
		this.applicationBundleName = bundleName;
		this.loadCommonChunks()
			.catch(error => {
				// This case is specifically for sip-trunking because soon there will be only single js file.
				// To not tight together release of webapp with sip-trunking release we need to handle both
				// cases when we have sip-trunking.js only and app-vendor.js + sip-trunking.js
				// Will get rid of this workaround in scope of https://idtjira.atlassian.net/browse/N2PSIPT-1661
				// tslint:disable-next-line:no-console
				console.error('error loading common chunks for', this.applicationBundleName, error);
			})
			.then(() => this.loadExternalAppScript())
			.then(() => this.getFeatureFlags())
			.then(featureFlags => this.initializeApp(featureFlags));
	}

	ngOnDestroy(): void {
		this.destroyApp();
	}

	private loadCommonChunks(): Promise<(void | ErrorEvent)[]> {
		const commonChunks = [`${this.externalAppUrl}/${EXTERNAL_APPS_VENDORS_CHUNK_NAME}.js`];
		return Promise.all(commonChunks.map(script => LoaderUtil.loadScript({ src: script })));
	}

	private loadExternalAppScript(): Promise<void | ErrorEvent> {
		return LoaderUtil.loadScript({
			src: `${this.externalAppUrl}/${this.applicationBundleName}.js`,
		});
	}

	private getFeatureFlags(): Promise<FeaturesInfo> {
		return this.featureFlagsService.getAllCommonFeatureFlags().toPromise();
	}

	private initializeApp(featureFlags: FeaturesInfo): void {
		this.app &&
			this.app.initialize(this.elementRef.nativeElement, {
				env: environment.env,
				apiUrl: getApiUrl(),
				apiV2Url: getApiV2Url(),
				authUrl: getAuthUrl(),
				accountId: this.authStorageData.accountId,
				userId: this.authStorageData.userId,
				userRole: this.authStorageData.userRole as IUserRole,
				lang: this.translationService.currentLanguage,
				countryCode: this.tenantService.countryCode,
				timezone: this.timezoneService.getCurrentUsersTimezone(),
				featureFlags,
				baseHref: environment.base_href,
				callbackFunction: () => this.callbackFunction.emit(),
				navigate: (commands: any[], extras?: NavigationExtras) => this.router.navigate(commands, extras),
				applications: ['UNITE'],
				additionalData: this.additionalData,
			});
	}

	private destroyApp(): void {
		this.app && this.app.destroy();
	}

	private get externalAppUrl(): string {
		switch (this.applicationBundleName) {
			case 'sip-trunking':
				return environment.sip_trunking;
			default:
				return environment.external_apps_url;
		}
	}
}
