import { Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/Subscription';
import { IDropdownOption } from 'n2p-ui-library/components/dropdown/dropdown.domain';
import { InitialsComponentBackground } from 'n2p-ui-library/constants';
import { forkJoin } from 'rxjs';

import {
	ApiDirectoriesService,
	ApiUsersService,
	CommonService,
	FeatureFlagsService,
	SnackbarService,
	AuthStorageData,
} from '@app/services';
import { DomesticNumberErrorAlertComponent } from '@app/pages/teammembers/components/domestic-number-error-alert/domestic-number-error-alert.component';
import { DialogService } from '@n2p';
import { DialogRef as N2PDialogRef } from '@n2p/dialog/dialogRef';
import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { mustHaveAtLeastOneDomesticNumberValidator } from '@utils/helpers/validators';
import { DepartmentService } from '@app/services/department/department.service';
import { DialogRef } from '@app/shared/classes/DialogRef';
import { ChipComponent } from '@app/shared';
import { FileSizeLevels, IAudio, ICompanyObject } from '@app/Common';
import { FileUploaderPopupComponent } from '@n2p/file-uploader';
import { AudioPlayerService } from '@n2p/audio-player';
import { IDepartment } from '@app/services/web-apis/departments/api-departments.domain';
import { IUser } from '@app/services/web-apis/users/api-users.domain';
import { IAccountPhone } from '@app/services/web-apis/phone-numbers/api-phone-numbers.domain';
import { TeamMemberService } from '@app/services/caller-id/team-member.service';
import { ApiTenantService } from '@app/services/web-apis/tenant/api-tenant.service';
import { CallOptionsService } from '../call-options/call-options.service';

@Component({
	selector: 'app-edit-team-member-company',
	templateUrl: './edit-team-member-company.component.html',
	moduleId: module.id,
	styleUrls: ['./edit-team-member-company.component.scss'],
})
export class EditTeamMemberCompanyComponent implements OnInit, OnDestroy {
	@Input() selectuserId: any;
	@Input() selectedUser: IUser;
	@ViewChild('snackbar_anchor', { static: true }) snackbarAnchor: ElementRef;
	formGroup: FormGroup;
	departments: IDepartment[];
	department: any[];
	departmentOptions: IDropdownOption[];
	selectedDepartmentIds: number[];
	editUserName: string = '';
	users: any[];
	userRole: string;
	showLoader: boolean = true;
	userid: string;
	userPhones: { lineId?: string; status?: string }[] = [];
	loggedInUserIsAdmin: boolean;
	saving: boolean = false;
	isRoleException: boolean = false;
	chipComponent: any = ChipComponent;
	openedOwnProfile: boolean;
	audioLoading: boolean = true;
	audio: IAudio | File | string;
	isCallServerV3User: boolean;
	canCreateDidlessUsers: boolean;
	subscriptions: Subscription = new Subscription();
	isPhoneNumberRequired: boolean;
	phoneNumbers: IAccountPhone[];
	private companyLoaded: boolean = false;
	private departmentsLoaded: boolean = false;
	private validating: boolean = false;
	private bounceMethod: any;
	private isCustomAudio: boolean = false;
	private isCustomAudioUploading: boolean = false;
	private shouldOpenCustomAudioDialog: boolean = false;

	constructor(
		@Inject(DIALOG_DATA) private data: any,
		@Inject(DIALOG_REF) private dialogRef: DialogRef,
		public cService: CommonService,
		public tenantService: ApiTenantService,
		private departmentService: DepartmentService,
		private snackbarService: SnackbarService,
		private fb: FormBuilder,
		private apiUsers: ApiUsersService,
		private translate: TranslateService,
		private dialogService: DialogService,
		private elementRef: ElementRef,
		private apiDirectory: ApiDirectoriesService,
		private featureFlags: FeatureFlagsService,
		private audioPlayerService: AudioPlayerService,
		private authStorageDataService: AuthStorageData,
		private teamMemberService: TeamMemberService,
		private userService: ApiUsersService,
		private callOptionsService: CallOptionsService,
	) {
		this.loggedInUserIsAdmin = cService.isAdmin();
		this.openedOwnProfile = Number(data.userId) === Number(cService.userId);
		this.isCustomAudio = data.compDir ? data.compDir.audioType === 2 : false;
		this.canCreateDidlessUsers = data.canCreateDidlessUsers;
		this.shouldOpenCustomAudioDialog = !this.isCustomAudio;
	}

	get numbersToSave(): any[] {
		const { userPhones: existingUserLines, getDenormalizedNumberList } = this;
		const formGroupLines = this.formGroup.get('assignedNumbers').value;
		// we're looking for any line that did not exist originally
		const newLines = formGroupLines.reduce((newLinesArray, formGroupLine) => {
			const isOriginal = existingUserLines.find(existingLine => existingLine.lineId === formGroupLine);
			if (!isOriginal) {
				newLinesArray.push(formGroupLine);
			}

			return newLinesArray;
		}, []);
		// items either have been added/deleted, or replaced (no change in array length)
		const hasBeenModified = formGroupLines.length !== existingUserLines.length || newLines.length;

		return hasBeenModified ? getDenormalizedNumberList(existingUserLines, formGroupLines, newLines) : undefined;
	}

	get isUserDataValid(): boolean {
		return (this.extension.disabled || this.extension.valid) && this.formGroup.get('assignedNumbers').valid;
	}

	get compDirEnabled(): AbstractControl {
		return this.formGroup.get('compDirEnabled');
	}

	get compDirAudioType(): AbstractControl {
		return this.formGroup.get('compDirAudioType');
	}

	get extension(): AbstractControl {
		return this.formGroup.get('extension');
	}

	private checkIsAudioExist(audio: IAudio | File | string): boolean {
		if (!audio) {
			return false;
		}

		if (typeof audio === 'string' || audio instanceof File) {
			return !!audio;
		}

		const { content, contentType, name } = audio as IAudio;

		return !!content || !!contentType || !!name;
	}

	get showTTSGeneratingMessage(): boolean {
		return ((this.isCustomAudio || !this.checkIsAudioExist(this.audio)) && this.isTTSAudio) || !this.isTTSLoaded;
	}

	get isTTSLoaded(): boolean {
		return this.compDirAudioType.value !== 0;
	}

	get isTTSAudio(): boolean {
		return this.compDirAudioType.value === 1;
	}

	get isSaveButtonDisabled(): boolean {
		return !this.isUserDataValid || this.formGroup.pristine;
	}

	ngOnInit(): void {
		this.showLoader = true;
		this.teamMemberService.getInitData(this.selectuserId).subscribe(() => {
			this.subscriptions.add(
				this.teamMemberService.isPhoneNumberRequired$.subscribe(value => (this.isPhoneNumberRequired = value)),
			);
			this.subscriptions.add(this.teamMemberService.phoneNumbers$.subscribe(value => (this.phoneNumbers = value)));
			this.isCallServerV3User = this.authStorageDataService.isV3CallServer;

			//	extra call for user to have up to date data about compDir
			this.subscriptions.add(
				this.userService.getUser(this.selectuserId).subscribe(({ data }) => {
					this.formGroup.get('compDirEnabled').setValue(data.compDir ? data.compDir.enabled : false);
					this.formGroup.get('compDirAudioType').setValue(data.compDir ? data.compDir.audioType : false);
					this.formGroup.markAsPristine();
				}),
			);

			this.formGroup = this.fb.group({
				name: this.editUserName,
				assignedNumbers: [
					[],
					(!this.canCreateDidlessUsers || this.isPhoneNumberRequired) && [
						Validators.required,
						mustHaveAtLeastOneDomesticNumberValidator,
					],
				],
				menuOptionSelected: [true],
				options: this.fb.array([
					this.fb.group({
						key: new FormControl(0, [Validators.required]),
						forwardCallsTo: [[], [Validators.required]],
					}),
				]),
				routeToId: '',
				type: ['user'],
				compDirEnabled: new FormControl(this.data.compDir ? this.data.compDir.enabled : false),
				// 0 - alphabetic (default)
				// 1 - tts
				// 2 - custom
				compDirAudioType: new FormControl(this.data.compDir ? this.data.compDir.audioType : 0),
				extension: new FormControl(
					{
						value: '',
						disabled: !this.cService.isAdmin(),
					},
					Validators.compose([Validators.required, Validators.min(100)]),
				),
			});

			this.departmentService.getAccountDepartments(true).subscribe(response => {
				this.departments = response.data;
				this.departmentsLoaded = true;
				this.showLoader = !this.isLoaded();
				this.getCompanyData();
			});

			this.callOptionsService.fetchAssignedPhoneNumbers().subscribe(userLines => {
				this.userPhones = userLines;
				const formGroupNumbersArray = this.userPhones.map(item => item.lineId);

				this.formGroup.get('assignedNumbers').setValue(formGroupNumbersArray);
				this.formGroup.get('routeToId').setValue(this.selectuserId);
				this.formGroup.markAsPristine();
			});

			if (this.openedOwnProfile || this.loggedInUserIsAdmin) {
				this.apiDirectory.getUserDirectoryPrompt(this.data.userId).subscribe(result => {
					if (!result.hasError) {
						this.changeAudio(<IAudio>{
							name: '',
							contentType: '',
							content: result.data.audioContent,
						});
					}
					this.audioLoading = false;
					this.showLoader = !this.isLoaded();
				});
			} else {
				this.audioLoading = false;
				this.showLoader = !this.isLoaded();
			}
		});
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
		this.teamMemberService.clearSubjects();
	}

	// called once it has been determined that phone number selection has been modified
	getDenormalizedNumberList(originalLines: any, modifiedLines: any, newLines: any): any[] {
		// original lines have the format { lineId: string }
		const originalHasBeenRemoved = line => !modifiedLines.find(modified => line.lineId === modified);
		return [
			...originalLines.map(originalLine => ({
				lineId: originalLine.lineId,
				status: originalHasBeenRemoved(originalLine) ? 'D' : 'A',
			})),
			...newLines.map(newLine => ({
				lineId: newLine,
				status: 'A',
			})),
		];
	}

	changeUserRole(newRole: 'regular' | 'admin'): void {
		this.userRole = newRole;
		this.formGroup.markAsDirty();
	}

	getCompanyData(): void {
		this.companyLoaded = true;
		this.showLoader = !this.isLoaded();

		this.userRole = this.selectedUser.role;
		this.extension.setValue(this.selectedUser.extension);

		this.editUserName = this.selectedUser.firstName + ' ' + this.selectedUser.lastName;
		this.formGroup.get('name').setValue(this.editUserName);

		this.setDepartmentsOptions();
	}

	close(): void {
		this.dialogRef.dismiss();
	}

	debounceSave(): void {
		if (this.bounceMethod) {
			clearTimeout(this.bounceMethod);
		}
		this.bounceMethod = setTimeout(() => {
			this.saveUserData();
		}, 800);
	}

	saveUserData(): void {
		if (this.validating) {
			this.debounceSave();
			return;
		}
		const assignedNumbers: Array<any> = this.formGroup.get('assignedNumbers').value;
		if (assignedNumbers.length === 1 && assignedNumbers[0].indexOf('+') !== -1) {
			// check for the + meaning int number
			this.showDomesticNumberError();

			return;
		}
		if (this.isUserDataValid) {
			this.saving = true;
			const updatedLinesToSave = this.numbersToSave;
			const departmentsInfo = this.generateDepartmentsInfo();

			let companyData: ICompanyObject = {
				user: {
					role: this.userRole,
					extension: this.extension.value,
					userId: this.selectuserId,
				},
				userLines: updatedLinesToSave
					? updatedLinesToSave
					: this.userPhones.map(line => ({
							...line,
							status: 'A',
					  })),
				userDepartments: departmentsInfo,
				userChatDirectories: departmentsInfo,
			};

			if (this.compDirEnabled.dirty || this.compDirAudioType.dirty) {
				companyData = {
					...companyData,
					compDir: {
						enabled: this.compDirEnabled.value,
						audioType: this.compDirAudioType.value,
					},
				};
			}

			this.isRoleException = false;

			this.apiUsers.updateUserCompanyInfo(this.selectuserId, companyData).subscribe(result => {
				this.saving = false;
				this.showLoader = false;
				if (!result.hasError) {
					this.teamMemberService.isPhoneNumberRequired$.next(result.data.userLines.length);
					this.data.extension = this.extension.value;
					this.data.role = this.userRole;
					this.data.members = departmentsInfo;
					this.data.compDir = {
						enabled: this.compDirEnabled.value,
						audioType: this.compDirAudioType.value,
					};

					this.dialogRef.dismiss(this.data);
					this.snackbarService.create({
						status: 'success',
						text: this.translate.instant('COMPANY_PAGE.COMPANY_INFO_SUCCESS_SAVE'),
						connectTo: undefined,
					});
				} else if (result.hasError && result.errorMessages) {
					const errorCode = result.errorMessages[0] && result.errorMessages[0].code;
					const templateToTranslate = `NOTIFICATIONS.ERRORS.${errorCode}`;
					const translatedMessage = this.translate.instant(templateToTranslate);
					const errorMessage =
						templateToTranslate === translatedMessage
							? this.translate.instant('NOTIFICATIONS.ERROR')
							: translatedMessage;
					switch (errorCode) {
						case '-2':
							this.isRoleException = true;
							break;
						default:
							this.snackbarService.createDanger(errorMessage, this.snackbarAnchor);
					}
				}
			});
		}
	}

	isLoaded(): boolean {
		return this.companyLoaded && this.departmentsLoaded && !this.audioLoading;
	}

	extensionValidating(validating: boolean): void {
		this.validating = validating;
	}

	changeAudio = (audio: IAudio | File | string): void => {
		this.audio = audio;
	};

	showFileUploadModal(): void {
		this.audioPlayerService.pauseAllAudio();
		this.isCustomAudioUploading = true;

		const dialogRef = this.dialogService.create(
			FileUploaderPopupComponent,
			{
				title: this.translate.instant('PROFILE_PAGE.COMPANY_DIRECTORY.UPLOAD_TITLE'),
				subtitle: this.translate.instant('PROFILE_PAGE.COMPANY_DIRECTORY.UPLOAD_SUBTITLE'),
				fileType: 'audio',
				uploadAction: 'createUserDirectoryPrompt',
				userId: this.selectuserId,
				maxFileSize: 20 * FileSizeLevels.MEGABYTE,
			},
			{ width: '465px' },
		);

		dialogRef.onDismiss().subscribe((audioFile: IAudio | File | string) => {
			this.isCustomAudioUploading = false;

			if (!audioFile && this.isCustomAudio) {
				return;
			}

			if (!audioFile && !this.isCustomAudio) {
				this.compDirAudioType.setValue(1);
				this.shouldOpenCustomAudioDialog = true;
				return;
			}

			this.changeAudio(audioFile);
			this.formGroup.markAsDirty();
			this.isCustomAudio = true;
			this.snackbarService.createSuccess(
				this.translate.instant('PROFILE_PAGE.COMPANY_DIRECTORY.UPLOAD_SUCCESS'),
				this.snackbarAnchor,
			);
		});
	}

	promptRadioButtonClick(value: number): void {
		if (value === 1 && !this.shouldOpenCustomAudioDialog) {
			this.shouldOpenCustomAudioDialog = true;
		} else if (value === 2 && this.shouldOpenCustomAudioDialog) {
			this.shouldOpenCustomAudioDialog = false;
			this.showFileUploadModal();
		}
	}

	setSelectedDepartmentIds(event: CustomEvent<IDropdownOption[]>): void {
		this.selectedDepartmentIds = event.detail.map(o => <number>o.value);
		this.formGroup.markAsDirty();
	}

	private setDepartmentsOptions(): void {
		this.showLoader = !this.isLoaded();

		this.departmentOptions = this.departments.map(d => ({
			title: d.name,
			subTitle: d.extension,
			value: d.deptId,
			initials: {
				value: d.name,
				background: InitialsComponentBackground.PRIMARY_DARK_1,
			},
		}));

		this.selectedDepartmentIds = this.selectedUser.members.map(m => m.id);
	}

	private showDomesticNumberError(): N2PDialogRef {
		return this.dialogService.create(
			DomesticNumberErrorAlertComponent,
			{},
			{
				width: 536,
			},
		);
	}

	private generateDepartmentsInfo(): { departmentId: number; displayName: string }[] {
		return this.departments
			.filter(d => this.selectedDepartmentIds.includes(d.deptId))
			.map(d => ({
				id: d.deptId,
				departmentId: d.deptId,
				displayName: d.name,
			}));
	}
}
