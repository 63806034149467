import { Component, Inject, OnInit } from '@angular/core';

import { DIALOG_DATA, DIALOG_REF } from '@n2p/dialog/dialog.injectors';
import { DialogRef } from '@n2p/dialog/dialogRef';

@Component({
	selector: 'n2p-add-forwarding-number',
	templateUrl: './add-forwarding-number.component.html',
	styleUrls: ['./add-forwarding-number.component.scss'],
})
export class AddForwardingNumberComponent implements OnInit {
	restrictedPhoneNumbers: Array<string>;
	isRingGroup: boolean;
	constructor(@Inject(DIALOG_REF) private dialogRef: DialogRef, @Inject(DIALOG_DATA) private data) {
		this.restrictedPhoneNumbers = data.restrictedPhoneNumbers;
		this.isRingGroup = data.isRingGroup;
	}

	onSubmit(num): void {
		this.dialogRef.dismiss(num);
	}

	onCancel(): void {
		this.dialogRef.dismiss();
	}

	ngOnInit(): void {}
}
